import StressManagementRelaxScreen from "../components/screens/StressManagementRelaxScreen";

const StressManagementRelaxPage = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <StressManagementRelaxScreen />
    </div>
  );
};

export default StressManagementRelaxPage;
