import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loggedUser } from "../../constants/loggedUser";
import styles from "./SideBar.module.css";
import SideBarButton from "./SideBarButton";
import txtA from "../../assets/UCA.txt";
import txtB from "../../assets/UCB.txt";
import txtC from "../../assets/UCC.txt";


const SideBar = ({ loggedIn }) => {
  const navigate = useNavigate();
  const [openDrivers, setOpenDrivers] = useState(false);
  const [opClicked, setOpClicked] = useState(false);
  const [drClicked, setDrClicked] = useState(false);
  const [enClicked, setEnClicked] = useState(false);
  const [lines, setLines] = useState([]);

  const user = loggedUser;



  const dropDownHandler = () => {
    const regexA = /_uca/;
    const regexB = /_ucb/;
    const regexC = /_ucc/;

    if (regexA.test(global.loggedInUserName)) {
      fetch(txtA)
        .then((response) => response.text())
        .then((data) => {
          const lines = data.split("\n");
          setLines(lines);
        })
        .catch((error) => console.error(error));
    }else if (regexB.test(global.loggedInUserName)){
      fetch(txtB)
        .then((response) => response.text())
        .then((data) => {
          const lines = data.split("\n");
          setLines(lines);
        })
        .catch((error) => console.error(error));
    }else{
      fetch(txtC)
        .then((response) => response.text())
        .then((data) => {
          const lines = data.split("\n");
          setLines(lines);
        })
        .catch((error) => console.error(error));
    }
    setOpenDrivers(!openDrivers);
  };


  return (
    <div style={{ width: "fit-content" }}>
      {!loggedIn && (
        <div className={styles.side_bar}>
          <img
            style={{ width: "100%", height: "33%" }}
            alt=""
            src={
              opClicked
                ? require("../../images/btn_oper_over.png")
                : require("../../images/btn_oper.png")
            }
            onClick={() => {
              setOpClicked(!opClicked);
            }}
          />
          <img
            style={{ width: "100%", height: "33%" }}
            alt=""
            src={
              drClicked
                ? require("../../images/btn_drrid_over.png")
                : require("../../images/btn_drrid.png")
            }
            onClick={() => {
              setDrClicked(!drClicked);
            }}
          />
          {/* <img
            style={{ width: "100%", height: "33%" }}
            alt=""
            src={
              enClicked
                ? require("../../images/btn_enforce_over.png")
                : require("../../images/btn_enforce.png")
            }
            onClick={() => {
              setEnClicked(!enClicked);
            }}
          /> */}
        </div>
      )}
      {loggedIn && (
        <div className={styles.side_bar}>
          <div className={styles.infoContainer}>
            <h5 className={styles.infoHeader}>{global.loggedInUserName}</h5>
            <p className={styles.infoTxt}>{global.loggedInUserCountry}</p>
          </div>
          {global.loggedInUserRole === "Driver" ? (
            <SideBarButton
              onClick={() => {
                navigate("/profile");
              }}
            >
              My profile
            </SideBarButton>
          ) : (
            <SideBarButton onClick={dropDownHandler}>
              Drivers
              {openDrivers && (
                <ul className={styles.dropdownmenu}>
                  {lines.map((user) => (
                    <li
                      className={styles.dropdownoption}
                      onClick={() => {
                        navigate("/profile", { state: { name: user } }); // use state for passing user name.
                      }}
                    >
                      {user}
                    </li>
                  ))}
                </ul>
              )}
            </SideBarButton>
          )}
          {global.loggedInUserRole === "Operator" && (
            <SideBarButton
              onClick={() => {
                navigate("/drivers_shift");
              }}
            >
              Driver's Shift
            </SideBarButton>
          )}
          {/* {global.loggedInUserRole === "Operator" && (
            <SideBarButton
              onClick={() => {
                navigate("/manage_users");
              }}
            >
              Manage Users
            </SideBarButton>
          )} */}
          {/* {global.loggedInUserRole === "Operator" && ( */}
            <SideBarButton
              onClick={() => {
                navigate("/alerts&notifications");
              }}
            >
              Alerts & Notifications
            </SideBarButton>
          {/* )} */}
          {global.loggedInUserRole === "Driver" && (
            <SideBarButton
              onClick={() => {
                navigate("/activity_feed");
              }}
            >
              Activity Feed
            </SideBarButton>
          )}
          {/* <SideBarButton
            onClick={() => {
              navigate("/analytics");
            }}
          >
            Insights
          </SideBarButton> */}
          <SideBarButton
            onClick={() => {
              navigate("/feedback");
            }}
          >
            FeedBack
          </SideBarButton>
          {global.loggedInUserRole === "Driver" ? (
            <SideBarButton
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Operator
            </SideBarButton>
          ) : (
            <SideBarButton
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Driver/Rider
            </SideBarButton>
          )}
        </div>
      )}
    </div>
  );
};

export default SideBar;
