import CountermeasuresScreen from "../components/screens/CountermeasuresScreen";

const CountermeasuresPage = () => {
  return (
    <div style={{ width: "100%", height: "80%", margin:"2vh" }}>
      <CountermeasuresScreen />
    </div>
  );
};
export default CountermeasuresPage;
