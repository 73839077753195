import { useState } from "react";

const ListItem = ({ item, selected, onSelect }) => {
  const [pressed, setPressed] = useState(false);

  const styles = {
    item: {
      padding: 16,
      backgroundColor: !selected
        ? "transparent"
        : "#337c75",
      borderRadius: 8,
      marginBottom: 8,
      marginTop: 8,
      width:'30vw'
    },
    itemText: {
      fontSize: 24,
      color: !selected ? "#337c75" : "white",
    },
  };

  return (
    <div>
      <button
        onClick={() => {
          setPressed(!pressed);
          onSelect(item);
        }}
        style={styles.item}
      >
        <p style={styles.itemText}>{item.title}</p>
      </button>
    </div>
  );
};

export default ListItem;
