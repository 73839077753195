import classes from "./Footer.module.css"

const Footer = () => {
  return (
    <footer>
        <div className={classes.logo}>
        <img
            className={classes.footerImg}
            src={require("../../assets/vehicle_sticker.jpg")}
            alt="EU logo"
          />
          {/* <img
            className={classes.footerImg}
            src={require("../../assets/Color_logo_no_background.png")}
            alt="EU logo"
          /> */}
        </div>
    </footer>
  );
};

export default Footer;