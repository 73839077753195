import { AiOutlineInfoCircle } from "react-icons/ai";
import styles from './FatigueReportItem.module.css'

const FatigueReportItem = ({ title, value, description }) => {

  return (
    <div className={styles.container}>
      <p className={styles.titleTxt}>{title}</p>
      <p className={styles.valueTxt}>{value}</p>
      <AiOutlineInfoCircle
        size={24}
        color="#337c75"
        onClick={() => {
          alert( description );
        }}
      />
    </div>
  );
};

export default FatigueReportItem;
