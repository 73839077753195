import React, { useState } from "react";
const NotifContext = React.createContext({
  msgReceived: false,
  alertReceived: false,
  msgReceivedHandler: () => {},
  alertReceivedHandler: () => {},
});

export const NotifContextProvider = (props) => {
  const [msgReceived, setMsgReceived] = useState(false);
  const [alertReceived, setAlertReceived] = useState(false);

  const msgReceivedHandler = (boolean) => {
    setMsgReceived(boolean);
  };
  const aletReceivedHandler = (boolean) => {
    setAlertReceived(boolean);
  };

  const contextValue = {
    msgReceived: msgReceived,
    alertReceived: alertReceived,
    msgReceivedHandler: msgReceivedHandler,
    aletReceivedHandler: aletReceivedHandler,
  };

  return (
    <NotifContext.Provider value={contextValue}>
      {props.children}
    </NotifContext.Provider>
  );
};

export default NotifContext;
