import { useState } from "react";

const minOffset = 0;
const maxOffset = 60;

const Yearpicker = ({selected, onYearChange }) => {
  const thisYear = new Date().getFullYear() - 18;
  const [year, setYear] = useState("Select Year");
  const options = [];

  for (let i = minOffset; i <= maxOffset; i++) {
    const tempYear = thisYear - i;
    if (selected=== tempYear){
      options.push(<option value={tempYear} selected>{tempYear}</option>);
    }
    else{
      options.push(<option value={tempYear}>{tempYear}</option>);
    }
    
  }

  return (
    <div>
      <select
        placeholder="Select year"
        value={selected}
        onChange={(e) => {
          setYear(e.target.value);
          onYearChange(e.target.value);
        }}
      >
        <option value="Select year">
          Select Year
        </option>
        {options}
      </select>
    </div>
  );
};

export default Yearpicker;
