import CountermeasuresDebriefScreen from "../components/screens/CountermeasuresDebriefScreen";

const CountermeasuresDebriefPage = ({mode}) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CountermeasuresDebriefScreen mode={mode}/>
    </div>
  );
};
export default CountermeasuresDebriefPage;
