import FatigueReportScreen from "../components/screens/FatigueReportScreen";

const FatigueReportPage = () => {
  return <div style={{ width: "100%", height: "100%" }}>

  {global.loggedInUserRole === "Operator" && (
        <div
          style={{
            height: "10%",
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#337c75",
            padding: "8px",
          }}
        >
          Fatigue Report for {global.selectedDriver}
        </div>
      )}
  <FatigueReportScreen/>
</div>
};

export default FatigueReportPage;
