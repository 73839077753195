import { useNavigate } from "react-router-dom";
import styles from "./CountermeasuresScreen.module.css";
import { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import { AiOutlineInfoCircle } from "react-icons/ai";
import fatigueInstructions from "../../assets/Fatigue incident debriefing.pdf";
import drugInstructions from "../../assets/Licit drug debriefing.pdf";
import AuthContext from "../../store/auth-context";

const CountermeasuresScreen = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fatigueCountermeasure, setFatigueCountermeasure] = useState("");
  const [drugCountermeasure, setDrugCountermeasure] = useState("");
  const [fatigueFormatted, setFatigueFormatted] = useState("");
  const [drugFormatted, setDrugFormatted] = useState("");
  const [fatigueRiskLevel, setFatigueRiskLevel] = useState("");
  const [fatigueStatus, setFatigueStatus] = useState("No value");
  const [fatigueLastChecked, setFatigueLastChecked] = useState("No value");
  const [stressStatus, setStressStatus] = useState("No value");
  const [stressLastChecked, setStressLastChecked] = useState("No value");
  const [drugStatus, setDrugStatus] = useState("No value");
  const [drugLastChecked, setDrugLastChecked] = useState("No value");
  const [benzodiazepinesLevel, setBenzodiazepinesLevel] = useState("No value");
  const [methadoneLevel, setMethadoneLevel] = useState("No value");
  const [questionnaireRiskLevel, setQuestionnaireRiskLevel] =
    useState("No Value");

  const handleMouseOver = (item) => {
    setHoveredItem(item);
  };

  const handleMouseOut = () => {
    setHoveredItem(null);
  };

  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const formattedDate = (date) => {
    const originalDate = new Date(date);

    const dd = String(originalDate.getDate()).padStart(2, "0");
    const mm = String(originalDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const yyyy = originalDate.getFullYear();
    const HH = String(originalDate.getHours()).padStart(2, "0");
    const MM = String(originalDate.getMinutes()).padStart(2, "0");

    const newDate = `${dd}-${mm}-${yyyy} ${HH}:${MM}`;
    return newDate;
  };

  useEffect(() => {
    const getInfo = async () => {
      const urls = [
        `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaFatigueDebriefing-${global.selectedDriver}`,
        `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaDrugDebriefing-${global.selectedDriver}`,
        `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaFatigueReport-${global.selectedDriver}`,
        `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaFatigueLevel-${global.selectedDriver}`,
        `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaStressLevel-${global.selectedDriver}`,
        `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaFatigueQuestionnaire-${global.selectedDriver}`,
        `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaLeitatMeasurement-${global.selectedDriver}`,
      ];

      try {
        // Use Promise.all to fetch data from all URLs concurrently
        const responses = await Promise.all(
          urls.map((url) =>
            fetch(url, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
          )
        );

        // Process responses
        const data = await Promise.all(
          responses.map(async (response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error(`HTTP error! Status: ${response.status}`);
          })
        );

        // Now, 'data' contains an array of results from the three requests
        if (data && data.length === 7) {
          console.log("DATA=>", data);

          if (data[0]?.realtime?.results?.bindings[0]) {
            setFatigueCountermeasure(
              data[0].realtime.results.bindings[0].recommendation.value
            );
            setFatigueFormatted(
              formattedDate(
                data[0].realtime.results.bindings[0].reportConductedOn.value
              )
            );
          }
          if (data[1]?.realtime?.results?.bindings[0]) {
            setDrugCountermeasure(
              data[1].realtime.results.bindings[0].recommendation.value
            );
            setDrugFormatted(
              formattedDate(
                data[1].realtime.results.bindings[0].reportConductedOn.value
              )
            );
          }
          if (data[2]?.realtime?.results?.bindings[0]) {
            setFatigueRiskLevel(
              data[2].realtime.results.bindings[0].riskLevel.value
            );
          }
          if (data[3]?.realtime?.results?.bindings[0]) {
            setFatigueStatus(
              data[3].realtime.results.bindings[0].fatigueValue.value
            );
            setFatigueLastChecked(
              formattedDate(
                data[3].realtime.results.bindings[0].dateObserved.value
              )
            );
          }
          if (data[4]?.realtime?.results?.bindings[0]) {
            setStressStatus(
              data[4].realtime.results.bindings[0].stressLevel.value
            );
            setStressLastChecked(
              formattedDate(
                data[4].realtime.results.bindings[0].dateObserved.value
              )
            );
          }
          if (data[5]?.realtime?.results?.bindings[0]) {
            setQuestionnaireRiskLevel(
              data[5].realtime.results.bindings[0].riskLevel.value
            );
          }
          if (data[6]?.realtime?.results?.bindings[0]) {
            setDrugStatus(
              data[6].realtime.results.bindings[0].drugStatus.value
            );
            setDrugLastChecked(
              formattedDate(
                data[6].realtime.results.bindings[0].dateObserved.value
              )
            );

            setBenzodiazepinesLevel(
              data[6].realtime.results.bindings[0].benzodiazepinesLevel.value
            );
            setMethadoneLevel(
              data[6].realtime.results.bindings[0].methadoneLevel.value
            );
          }

          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setIsLoading(false);
        // Handle the error gracefully (e.g., set isLoading to false or display an error message)
      }
    };
    getInfo();
  }, []);

  const navigate = useNavigate();
  let content;
  if (global.driverShift === "0") {
    content = "Off Duty";
  } else if (global.driverShift === "1") {
    content = "On Duty";
  } else if (global.driverShift === "2") {
    content = "Pre Driving";
  } else if (global.driverShift === "3") {
    content = "On Site";
  } else if (global.driverShift === "4") {
    content = "On Lunch";
  } else {
    content = null;
  }

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.innerContainer}>
          <div className={styles.headerTxt}>
            Countermeasures for {global.selectedDriver}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img
                className={styles.profileImg}
                src={require("../../images/profile_picture.png")}
                alt="Profile"
              />
              <div
                className={styles.shiftContainer}
                style={{
                  backgroundColor:
                    global.driverShift === "1"
                      ? "#3ab54a"
                      : global.driverShift === "0"
                      ? "#f32929"
                      : global.driverShift === "2"
                      ? "#df7229"
                      : global.driverShift === "3"
                      ? "#3a98b5"
                      : global.driverShift === "4"
                      ? "#b53ab5"
                      : "transparent",
                }}
              >
                <p className={styles.shiftTxt}>{content}</p>
              </div>
            </div>

            <table>
              <thead>
                <tr>
                  <th className={styles.emptyShell}></th>
                  <th>Fatigue</th>
                  <th>Stress</th>
                  <th>Licit drugs</th>
                </tr>

                <tr>
                  <th>
                    Status (Live)
                    <AiOutlineInfoCircle
                      size={24}
                      color="#337c75"
                      onClick={() => {
                        alert(
                          `The "(Live) Status” displays the last recorded values of a driver impairments/risk factors levels (1 - 5). A value of 5 requires immediate action from the Operator.`
                        );
                      }}
                    />
                  </th>
                  <td>{fatigueStatus}</td>
                  <td>{stressStatus}</td>
                  <td>{drugStatus}</td>
                </tr>
                <tr>
                  <th>Last Checked</th>
                  <td>{fatigueLastChecked}</td>
                  <td>{stressLastChecked}</td>
                  <td>{drugLastChecked}</td>
                </tr>
              </thead>
            </table>
          </div>
          <table>
            <thead>
              <tr>
                <th>
                  Safety Profile{" "}
                  <AiOutlineInfoCircle
                    size={24}
                    color="#337c75"
                    onClick={() => {
                      alert(
                        `The "Safety Profile" provides a risk assessment informed by the available information on the driver's fatigue and legal drugs, these are: the most recent Fatigue Report, Fatigue Questionnaire and Drug Test results. To open the results referred to the current driver, click on the related button.`
                      );
                    }}
                  />
                </th>
                <td>
                  <tr>
                    <th>Fatigue</th>
                  </tr>
                  <tr>
                    <td
                      onClick={() => navigate("/fatigue_report")}
                      onMouseOver={() => handleMouseOver("fatigue_report")}
                      onMouseOut={() => handleMouseOut}
                      style={{
                        color:
                          hoveredItem === "fatigue_report" ? "blue" : "black",
                        cursor:
                          hoveredItem === "fatigue_report"
                            ? "pointer"
                            : "default",
                        backgroundColor:
                          fatigueRiskLevel === "MODERATE"
                            ? "yellow"
                            : fatigueRiskLevel === "SAFE"
                            ? "green"
                            : fatigueRiskLevel === "UNSAFE"
                            ? "red"
                            : "transparent",
                      }}
                    >
                      Open Fatigue Report
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: "bold",
                        color:
                          questionnaireRiskLevel === "RECOMMENDED"
                            ? "orange"
                            : questionnaireRiskLevel === "HIGHLY RECOMMENDED"
                            ? "red"
                            : "green",
                      }}
                    >
                      Fatigue Questionnaire: {questionnaireRiskLevel}
                    </td>
                  </tr>
                </td>
                <td>
                  <tr>
                    <th>Licit Drugs</th>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      Benzodiazepines Level is:{" "}
                      <p style={{ fontWeight: "bold" }}>
                        {benzodiazepinesLevel}
                      </p>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      Methadone Level is:{" "}
                      <p style={{ fontWeight: "bold" }}>{methadoneLevel}</p>
                    </td>
                  </tr>
                </td>
              </tr>

              <tr>
                <th>
                  Countermeasures{" "}
                  <AiOutlineInfoCircle
                    size={24}
                    color="#337c75"
                    onClick={() => {
                      alert(
                        `The "Countermeasures area" provides the operator with suggested actions to be taken, i.e. debriefing regarding fatigue and the use of legal drugs. If a countermeasure is "Recommended" or "highly recommended", then the Operator can open the instructions on conducting a debriefing session with the driver and plan it. After the debriefing session, the Operator is asked to fill in a short questionnaire to evaluate the main risk factors for the Driver and make notes on their state.`
                      );
                    }}
                  />
                </th>
                <td>
                  <tr>
                    <th
                      style={{
                        color:
                          fatigueCountermeasure === "RECOMMENDED"
                            ? "orange"
                            : fatigueCountermeasure === "HIGHLY RECOMMENDED"
                            ? "red"
                            : "green",
                      }}
                    >
                      Recommendation: {fatigueCountermeasure}
                    </th>
                  </tr>
                  <tr>
                    <td
                      onClick={() => {
                        window.open(fatigueInstructions, "_blank");
                      }}
                      onMouseOver={() =>
                        handleMouseOver("fatigue_instructions")
                      }
                      onMouseOut={() => handleMouseOut}
                      style={{
                        color:
                          hoveredItem === "fatigue_instructions"
                            ? "blue"
                            : "black",
                        cursor:
                          hoveredItem === "fatigue_instructions"
                            ? "pointer"
                            : "default",
                      }}
                    >
                      Instructions
                    </td>
                    <td
                      onClick={() =>
                        navigate("/countermeasures_fatigue_debrief")
                      }
                      onMouseOver={() => handleMouseOver("fatigue_debrief")}
                      onMouseOut={() => handleMouseOut}
                      style={{
                        color:
                          hoveredItem === "fatigue_debrief" ? "blue" : "black",
                        cursor:
                          hoveredItem === "fatigue_debrief"
                            ? "pointer"
                            : "default",
                      }}
                    >
                      Enter report
                    </td>
                  </tr>
                  <tr>
                    <td>{fatigueFormatted}</td>
                  </tr>
                </td>
                <td>
                  <tr>
                    <th
                      style={{
                        color:
                          drugCountermeasure === "RECOMMENDED"
                            ? "orange"
                            : drugCountermeasure === "HIGHLY RECOMMENDED"
                            ? "red"
                            : "green",
                      }}
                    >
                      Recommendation: {drugCountermeasure}
                    </th>
                  </tr>
                  <tr>
                    <td
                      onClick={() => {
                        window.open(drugInstructions, "_blank");
                      }}
                      onMouseOver={() => handleMouseOver("drug_instructions")}
                      onMouseOut={() => handleMouseOut}
                      style={{
                        color:
                          hoveredItem === "drug_instructions"
                            ? "blue"
                            : "black",
                        cursor:
                          hoveredItem === "drug_instructions"
                            ? "pointer"
                            : "default",
                      }}
                    >
                      Instructions
                    </td>
                    <td
                      onClick={() => navigate("/countermeasures_drug_debrief")}
                      onMouseOver={() => handleMouseOver("drug_debrief")}
                      onMouseOut={() => handleMouseOut}
                      style={{
                        color:
                          hoveredItem === "drug_debrief" ? "blue" : "black",
                        cursor:
                          hoveredItem === "drug_debrief"
                            ? "pointer"
                            : "default",
                      }}
                    >
                      Enter report
                    </td>
                  </tr>
                  <tr>
                    <td>{drugFormatted}</td>
                  </tr>
                </td>
              </tr>
            </thead>
          </table>
        </div>
      )}
    </div>
  );
};

export default CountermeasuresScreen;
