const getColor = (level) => {
  switch (level) {
    case 1:
      return "#0096ff";
    case 2:
      return "#00ef1a";
    case 3:
      return "#a5ff7b";
    case 4:
      return "#ffee1a";
    case 5:
      return "#ffbe84";
    default:
      return "#eaeaea";
  }
};

const BarChart = ({ children, data }) => {
  const styles = {
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: 24,
      fontWeight: "bold",
      color: "#337c75",
      marginLeft: 24,
      marginRight: 24,
      marginTop: 16,
      marginBottom: 16,
      textAlign: "center",
    },
    //for bar
    barContainer: {
      flexDirection: "column-reverse",
      width: "12vh",
      height: "50vh",
    },
    bar: {
      display: "flex",
    },
  };
  return (
    <div style={styles.innerContainer}>
      <p style={styles.text}>{children}</p>
      <div style={styles.barContainer}>
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              ...styles.bar,
              height: item.level * 30,
              backgroundColor: getColor(item.level),
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default BarChart;
