import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './store/auth-context';
import { NotifContextProvider } from './store/notif-context';
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Import Helmet and HelmetProvider

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider> {/* Wrap your app with HelmetProvider */}
    <AuthContextProvider>
      <NotifContextProvider>
        <BrowserRouter>
          <Helmet> {/* Use Helmet component to inject Google Analytics script tags */}
            {/* Google Analytics tracking code */}
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-H3B3R8CHFK"></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-H3B3R8CHFK');
              `}
            </script>
          </Helmet>
          <App />
        </BrowserRouter>
      </NotifContextProvider>
    </AuthContextProvider>
  </HelmetProvider>
);
