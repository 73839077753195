import styles from "./Button.module.css";

const Button = ({ children, onClick, mode, style }) => {
  return (
    <div className={style}>
      <button
        onClick={onClick}
        className={mode === "flat" ? styles.flat : styles.button}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
