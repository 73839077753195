import { useNavigate } from "react-router-dom";
import styles from "./FatigueReportScreen.module.css";
import { useContext, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import Button from "../UI/Button";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import FatigueReportItem from "../UI/FatigueReportItem";
import { useEffect } from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import AuthContext from "../../store/auth-context";

const FatigueReportScreen = () => {
  const navigate = useNavigate();
  let username;
  if (global.loggedInUserRole === "Operator") {
    username = global.selectedDriver;
  } else {
    username = global.loggedInUserName;
  }
  const [submitted, setSubmitted] = useState(false);
  // const [value, onChange] = useState();
  const [value, setValue] = useState(0);

  const [dateObserved, setDateObserved] = useState("No value");
  const [fatigueLevels, setFatigueLevels] = useState();
  const [riskLevel, setRiskLevel] = useState();
  const [numberOfFatigueEvents, setNumberOfFatigueEvents] = useState();
  const [preQuestionnaire, setPreQuestionnaire] = useState();
  const [sleepDuration, setSleepDuration] = useState();
  const [sleepScore, setSleepScore] = useState();
  const [timeOverThreshold, setTimeOverThreshold] = useState();
  const [isLoading, setIsLoading] = useState(true);

  let lineChartData;
  let thresholdLineData;
  let mergedArray;
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  

  useEffect(() => {
    const getParticipant = async () => {
      let date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 19); //current date
      let thirtyDaysAgo = new Date(
        new Date().getTime() - 30 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .slice(0, 19); // 7 days ago
      console.log("DATE=>", date);
      console.log("DATE 7=>", thirtyDaysAgo);
      const panaceaFatigueReportUrl = `https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaFatigueReport-${username}&fromTime=${thirtyDaysAgo}&toTime=${date}`;
      try {
        const getResponse = await fetch(panaceaFatigueReportUrl,{
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setDateObserved(data.realtime.results.bindings[value].recordedAt.value)
            setFatigueLevels(
              data.realtime.results.bindings[value].fatigueLevels.value
            );
            setRiskLevel(data.realtime.results.bindings[value].riskLevel.value);
            setTimeOverThreshold(
              data.realtime.results.bindings[value].timeOverThreshold.value
            );
            setNumberOfFatigueEvents(
              data.realtime.results.bindings[value].numberOfFatigueEvents.value
            );
            setPreQuestionnaire(
              data.realtime.results.bindings[value].preQuestionnaire.value
            );
            setSleepDuration(
              data.realtime.results.bindings[value].sleepDuration.value
            );
            setSleepScore(data.realtime.results.bindings[value].sleepScore.value);
            console.log("DATA=>", data, value);
          });
        setIsLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    getParticipant();
  }, [fatigueLevels, username,value]);

  const formattedDate = new Date(dateObserved).toLocaleString("en-GB", {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  });

  if (fatigueLevels) {
    if (fatigueLevels) {
      const parsedFatigueLevels = JSON.parse(fatigueLevels);
      lineChartData = parsedFatigueLevels.reverse();
      thresholdLineData = parsedFatigueLevels.map((item) => ({
        timestamp: item.timestamp,
        value: 4,
      }));
    } else {
      lineChartData = [];
      thresholdLineData = [];
    }
    mergedArray = thresholdLineData.map((item, index) => ({
      Threshold: item.value,
      Data: lineChartData[index].value,
      timestamp: `${new Date(item.timestamp)
        .getUTCHours()
        .toString()
        .padStart(2, "0")}:${new Date(item.timestamp)
        .getUTCMinutes()
        .toString()
        .padStart(2, "0")}`,
    }));
  }

  const data = [
    {
      id: "Time over threshold",
      title: "Time over threshold:",
      value: timeOverThreshold,
      description:
        "When you are driving fatigued, you are more likely to miss important events on the road, react too slow, or even fall asleep at the wheel!",
    },
    {
      id: "Fatigue event",
      title: "Fatigue event:",
      value: numberOfFatigueEvents,
      description: `Fatigue events such as "microsleeps" can be especially dangerous, as they are often unnoticed. During a 3-second microsleep at 60km/h, you travel 50 meters.`,
    },
    {
      id: "Pre-Q level",
      title: "Pre-Q level:",
      value: preQuestionnaire,
      description:
        "Starting with a lower level of alertness makes you more likely to experience fatigue-related problems later in the shift.",
    },
    {
      id: "Sleep duration",
      title: "Sleep duration:",
      value: (parseInt(sleepDuration, 10) / 60).toFixed(2) + " hours",
      description:
        "For most adults, the optimum amount of sleep for healthy everyday functioning is 7-9 hours.",
    },
    {
      id: "Sleep score",
      title: "Sleep score:",
      value: sleepScore,
      description:
        "Good sleep is characterised by falling asleep in 30 minutes, not waking up more than twice and spending less than 15% of the time in bed awake.",
    },
  ];

  const submitToOperator = () => {
    console.log("Submitted to operator");
    setSubmitted(true);
  };

  const handleDecrementClick = () => {
    // Ensure value is never less than 0
    const newValue = Math.max(value - 1, 0);
    setValue(newValue);
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.innerContainer}>
          {/* <DateTimePicker
            onChange={onChange}
            value={value}
            format="dd-MM-y HH:mm:ss"
          /> */}
          <div className={styles.arrowContainer}>
            <img
              className={styles.img}
              src={require("../../assets/arrow_left.png")}
              alt="Left arrow"
              onClick={()=>{setValue(value+1)}}
            />
            <p className={styles.text}>{formattedDate}</p>
            <img
              className={styles.img}
              src={require("../../assets/arrow_right.png")}
              alt="Right arrow"
              onClick={handleDecrementClick}
            />
          </div>

          <p className={styles.text}>Fatigue Level: {riskLevel}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginVertical: 8,
              alignItems: "center",
            }}
          >
            <LineChart
              width={600}
              height={300}
              justify="center"
              data={mergedArray}
            >
              <XAxis
                dataKey="timestamp"
                tickCount={mergedArray ? mergedArray.lenght % 10 : 1}
              />
              <YAxis domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} />
              <CartesianGrid stroke="#eee" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="Data" stroke="#337c75" />
              <Line type="monotone" dataKey="Threshold" stroke="red" />
            </LineChart>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginVertical: 8,
              alignItems: "center",
            }}
          >
            {data.map((item) => (
              <FatigueReportItem
                key={item.id}
                title={item.title}
                value={item.value}
                description={item.description.toString()}
              />
            ))}
          </div>

          {!submitted && global.loggedInUserRole === "Driver" && (
            <div className={styles.buttonContainer}>
              <p className={styles.text}>Submit:</p>
              <Button className={styles.button} onClick={submitToOperator}>
                Yes
              </Button>
              <Button
                className={styles.button}
                onClick={() => {
                  navigate("/profile");
                }}
              >
                No
              </Button>
              <AiOutlineInfoCircle
                size={24}
                color="#337c75"
                onClick={() => {
                  alert(
                    "Submiting the report to the operator will help them understand your patterns of fatigue and support them in finding appropriate solutions."
                  );
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default FatigueReportScreen;
