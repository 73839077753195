import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";

const StressManagementRelaxScreen = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      height: "40vh",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    button: {
      marginTop: 20,
      marginBottom: 20,
      padding: 8,
    },
    text: {
      fontSize: 24,
      fontWeight: "bold",
      color: "#337c75",
      marginLeft: 24,
      marginRight: 24,
      marginTop: 16,
      marginBottom: 16,
      textAlign: "justify",
    },
  };

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <p style={styles.text}>Let's relax for a bit!</p>
      <div style={styles.buttonContainer}>
        <Button
          style={styles.button}
          onClick={() =>
            alert(
              "To play the Balloon Game you must install it in your mobile phone."
            )
          }
        >
          <p style={{ fontSize: 24 }}>Open Balloon game</p>
        </Button>
        <Button
          style={styles.button}
          onClick={() => {
            navigate("/stress_timer");
          }}
        >
          <p style={{ fontSize: 24 }}>I'll relax on my own</p>
        </Button>
        {/* <Button
          style={styles.button}
          onClick={() => {
            navigate("/stress_results");
          }}
        >
          <p style={{ fontSize: 24 }}>Check stress levels</p>
        </Button> */}
      </div>
    </div>
  );
};

export default StressManagementRelaxScreen;
