import { useState } from "react";
import styles from "./RadioButtonQuestion.module.css";
const RadioButtonQuestion = ({ children, questionId, onAnswerChange  }) => {
  const [agreement, setAgreement] = useState(null);

  const handleAgreementChange = (text,value) => {
    setAgreement(text);
    onAnswerChange(questionId, `${questionId}_${value}`);
  };

  return (
    <form className={styles.container}>
      <div>
        <label className={styles.question}>{children}</label>
      </div>
      <div className={styles.innerContainer}>
        <div>
          <label className={styles.answer}>
            <input
              type="radio"
              value="0"
              checked={agreement === "Strongly Disagree"}
              onChange={() => handleAgreementChange("Strongly Disagree","0")}
            />
            <p>Strongly Disagree</p>
          </label>
        </div>
        <div>
          <label className={styles.answer}>
            <input
              type="radio"
              value="1"
              checked={agreement === "Disagree"}
              onChange={() => handleAgreementChange("Disagree","1")}
            />
            <p>Disagree</p>
          </label>
        </div>
        <div>
          <label className={styles.answer}>
            <input
              type="radio"
              value="2"
              checked={agreement === "Neutral"}
              onChange={() => handleAgreementChange("Neutral","2")}
            />
            <p>Neutral</p>
          </label>
        </div>
        <div>
          <label className={styles.answer}>
            <input
              type="radio"
              value="3"
              checked={agreement === "Agree"}
              onChange={() => handleAgreementChange("Agree","3")}
            />
            <p>Agree</p>
          </label>
        </div>
        <div>
          <label className={styles.answer}>
            <input
              type="radio"
              value="4"
              checked={agreement === "Strongly Agree"}
              onChange={() => handleAgreementChange("Strongly Agree","4")}
            />
            <p>Strongly Agree</p>
          </label>
        </div>
      </div>
    </form>
  );
};
export default RadioButtonQuestion;
