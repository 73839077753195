import React, { useState } from "react";
import EmojiButton from "./EmojiButton";
import ListItem from "./ListItem";

const Question = ({
  children,
  mode,
  mode2,
  data,
  onEmojiPress,
  onListItemPress,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const onSelectItem = (item) => {
    setSelectedItem(item);
    onListItemPress(item); // Pass the selected item to the parent component
  };

  const onSelectItems = (item) => {
    const updatedSelection = [...selectedItems];
    const index = updatedSelection.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    if (index > -1) {
      // Item is already selected, remove it from the selection
      updatedSelection.splice(index, 1);
    } else {
      // Item is not selected, add it to the selection
      updatedSelection.push(item);
    }

    setSelectedItems(updatedSelection);
    onListItemPress(updatedSelection); // Pass the updated selection to the parent component
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "16px",
    },
    header: {
      fontSize: "32px",
      fontWeight: "bold",
      color: "#337c75",
      margin: "16px 0",
      textAlign: "center",
    },
    emojiContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    listContainer: {
      height: "70vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "auto"
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>{children}</div>

      {mode === "emoji" && mode2 === "normal" && (
        <div style={styles.emojiContainer}>
          <EmojiButton
            emoji="😃"
            onPress={() =>
              onEmojiPress({ id: "opinion_0_0", text: "Not at all" })
            }
          >
            Not at all
          </EmojiButton>
          <EmojiButton
            emoji="🙂"
            onPress={() =>
              onEmojiPress({ id: "opinion_0_1", text: "A little" })
            }
          >
            A little
          </EmojiButton>
          <EmojiButton
            emoji="😐"
            onPress={() =>
              onEmojiPress({ id: "opinion_0_2", text: "Somewhat" })
            }
          >
            Somewhat
          </EmojiButton>
          <EmojiButton
            emoji="🙁"
            onPress={() => onEmojiPress({ id: "opinion_0_3", text: "Quite" })}
          >
            Quite
          </EmojiButton>
          <EmojiButton
            emoji="☹️ "
            onPress={() => onEmojiPress({ id: "opinion_0_4", text: "A lot" })}
          >
            A lot
          </EmojiButton>
        </div>
      )}
      {mode === "emoji" && mode2 === "reverse" && (
        <div style={styles.emojiContainer}>
          <EmojiButton
            emoji="😃"
            onPress={() => onEmojiPress({ id: "opinion_1_0", text: "Very" })}
          >
            Very
          </EmojiButton>
          <EmojiButton
            emoji="🙂"
            onPress={() => onEmojiPress({ id: "opinion_1_1", text: "Quite" })}
          >
            Quite
          </EmojiButton>
          <EmojiButton
            emoji="😐"
            onPress={() =>
              onEmojiPress({ id: "opinion_1_2", text: "Somewhat" })
            }
          >
            Somewhat
          </EmojiButton>
          <EmojiButton
            emoji="🙁"
            onPress={() =>
              onEmojiPress({ id: "opinion_1_3", text: "Not really" })
            }
          >
            Not really
          </EmojiButton>
          <EmojiButton
            emoji="☹️ "
            onPress={() =>
              onEmojiPress({ id: "opinion_1_4", text: "Not at all" })
            }
          >
            Not at all
          </EmojiButton>
        </div>
      )}
      {mode === "list" && mode2 === "single" && (
        <div style={styles.listContainer}>
          {data.map((item) => (
            <ListItem
              key={item.id}
              item={item}
              selected={item.id === (selectedItem ? selectedItem.id : null)}
              onSelect={onSelectItem}
            />
          ))}
        </div>
      )}
      {mode === "list" && mode2 === "multiple" && (
        <div style={styles.listContainer}>
          {data.map((item) => (
            <ListItem
              key={item.id}
              item={item}
              selected={selectedItems.some(
                (selectedItem) => selectedItem.id === item.id
              )}
              onSelect={onSelectItems}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Question;
