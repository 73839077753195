// import ContactScreen from "../components/screens/ContactScreen";
import SendbirdApp from '@sendbird/uikit-react/App';
import '@sendbird/uikit-react/dist/index.css';
import { APP_ID } from "../constants/data";

const myColorSet = {
  '--sendbird-light-primary-500': '#337c75',
  '--sendbird-light-primary-400': '#8c6eab',
  '--sendbird-light-primary-300': '#157e8f',
  '--sendbird-light-primary-200': '#5c78b0',
  '--sendbird-light-primary-100': 'white',
};

const ContactPage=()=>{
    return <div style={{width: '90vw', height: '90vh'}}>
      {/* <ContactScreen admin={global.loggedInUserRole === "Operator"?true:false}/> */}
      <SendbirdApp
                // Add the two lines below.
                appId={APP_ID}   // Specify your Sendbird application ID.
                userId={global.loggedInUserName}        // Specify your user ID.
                // theme='dark'
                nickname={global.loggedInUserName}
                colorSet={myColorSet}
            />
  </div>
}

export default ContactPage;