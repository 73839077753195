import React, { useEffect, useState } from "react";
import { Alerts } from "../../constants/alerts";
import { Notify } from "../../constants/notify";

const mqtt = require("mqtt/dist/mqtt");

function Notifications({ role }) {
  let options;
  let topic;
  if (role === "Operator") {
    options = {
      protocolId: "MQTT",
      keepalive: 20,
      clientId: "operator" + Math.random().toString(16).substr(2, 8),
      username: "user1",
      password: "unisystems",
    };
    topic = `panacea/dss/hmi/${global.loggedInUserName}/`;
  } else {
    options = {
      protocolId: "MQTT",
      keepalive: 20,
      clientId: "id_" + Math.random().toString(16).substr(2, 8),
      username: "user1",
      password: "unisystems",
    };
    topic = `panacea/dss/hmi/${global.loggedInUserName}/`;
  }

  // const client = mqtt.connect("wss://broker.emqx.io:8084/mqtt",{clientId: options.clientId});
  const client = mqtt.connect(
    "wss://tdd9e3da.ala.us-east-1.emqxsl.com:8084/mqtt",
    options
  );

  client.subscribe(topic);
  console.log("Client subscribed!");

  const [state, setState] = useState({
    topic: topic,
    subscribedTopic: topic,
    message: "",
    status: "connected",
  });

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);
  useEffect(() => {
    // console.log("HERE OP!");
    if (client) {
      client.on("message", (topic, message) => {
        //JSON object for message received
        let msgObj = JSON.parse(message);
        console.log(msgObj);
        setState((prevState) => ({
          ...prevState,
          message: msgObj.message,
        }));
        let content =
          msgObj.state + ", " + msgObj.participantID + " is " + msgObj.message;
        if (msgObj.alert === true) {
          if (Alerts.length >= 0) {
            const lastAlert = Alerts[Alerts.length - 1];
            console.log("Last Alert:", lastAlert);
            if (lastAlert !== content) {
              Alerts.push(content);
              var optionsAlert = {
                body:
                  "Driver " + msgObj.participantID + " is " + msgObj.message,
                // icon: "../../images/adaptive-icon.png",
                dir: "ltr",
              };
              new Notification(msgObj.state, optionsAlert);
            }
          }
        } else {
          if (Notify.length >= 0) {
            const lastNotify = Notify[Notify.length - 1];
            console.log("Last Notify:", lastNotify);
            if (lastNotify !== content) {
              Notify.push(content);
              var optionsNotify = {
                body:
                  "Driver " + msgObj.participantID + " is " + msgObj.message,
                // icon: "../../images/adaptive-icon.png",
                dir: "ltr",
              };
              new Notification(msgObj.state, optionsNotify);
            }
          }
        }
      });
    }
  }, [state, client]);
  //   const showNotification = () => {
  //     var options = {
  //       body: "Notification Body",
  //       dir: "ltr",
  //     };
  //     new Notification("Hello World", options);
  //   };

  //   return (
  //     <div>
  //       <button onClick={showNotification}>Show notification</button>
  //     </div>
  //   );
}

export default Notifications;
