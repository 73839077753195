import { useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import { useContext, useEffect, useState } from "react";
import Question from "../UI/Question";
import AuthContext from "../../store/auth-context";

const FatigueQuestionnaireScreen = () => {
  const username = global.loggedInUserName;
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const navigate = useNavigate();
  const [surePressed, setSurePressed] = useState(false);
  const [goToSecondQuestion, setGoToSecondQuestion] = useState(false);
  const [goToThirdQuestion, setGoToThirdQuestion] = useState(false);
  const [goToFourthQuestion, setGoToFourthQuestion] = useState(false);
  const [goToFifthQuestion, setGoToFifthQuestion] = useState(false);
  const [goToSubmit, setGoToSubmit] = useState(false);
  const [negativeEmoji, setNegativeEmoji] = useState(false);
  const [negativeList, setNegativeList] = useState(true);
  const [showTips, setShowTips] = useState(false);
  const [emojiID, setEmojiID] = useState();
  const [listItemID, setListItemID] = useState();

  const [fatigueQuestionnaireResponses, setFatigueQuestionnaireResponses] =
    useState([]);

  useEffect(() => {
    const getParticipant = async () => {
      const PanaceaFatigueQuestionnaireUrl =
        "https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaFatigueQuestionnaire-" +
        username;
      fetch(PanaceaFatigueQuestionnaireUrl)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          // console.log(getResponse.data.realtime.results.bindings[0]);
        })
        .catch((error) => console.log(error));
    };
    getParticipant();
  }, []);
  //data for third question
  const dataThird = [
    { id: "list_0_0", title: "Have more breaks" },
    { id: "list_0_1", title: "Make it shorter" },
    { id: "list_0_2", title: "Change shift type" },
    { id: "list_0_3", title: "Other" },
  ];

  //data for fourth question
  const dataFourth = [
    { id: "list_1_0", title: "Opened windows" },
    { id: "list_1_1", title: "Took a nap" },
    { id: "list_1_2", title: "Had a coffee" },
    { id: "list_1_3", title: "Listened to music" },
    { id: "list_1_4", title: "Stopped for a stretch" },
    { id: "list_1_5", title: "Ate something sweet" },
    { id: "list_1_6", title: "Engaged in conversation" },
    { id: "list_1_7", title: "Other" },
  ];

  //data for fifth question
  const dataFifth = [
    { id: "list_2_0", title: "Lifestyle & health" },
    { id: "list_2_1", title: "Sleeping patterns" },
    { id: "list_2_2", title: "Sleeping environment" },
    { id: "list_2_3", title: "Naps and breaks" },
    { id: "list_2_4", title: "Other" },
  ];

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      width: "50%",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    header: {
      fontSize: 32,
      fontWeight: "bold",
      color: "#337c75",
      marginTop: 16,
      marginBottom: 16,
    },
    text: {
      fontSize: 26,
      fontWeight: "bold",
      color: "#337c75",
      marginLeft: 24,
      marginRight: 24,
      marginTop: 16,
      marginBottom: 16,
      textAlign: "center",
    },
  };

  const handleEmojiPress = (answer) => {
    // Handle the selected emoji answer here
    for (let i = 2; i < 5; i++) {
      //if emoji id is between these 2 values the choice is negative and the user is prompted to Question 3
      if (answer.id >= "opinion_1_2" && answer.id <= "opinion_1_4") {
        setNegativeEmoji(true);
      } else {
        setNegativeEmoji(false);
      }
    }
    setEmojiID(answer.id);
  };

  const handleListItemPress = (selectedItem) => {
    // Handle the selected ListItem here
    setListItemID(selectedItem.id);
  };

  const handleListItemsPress = (selectedItem) => {
    let idsString = "";

    for (let i = 0; i < selectedItem.length; i++) {
      // if the user picked somthing other than nap and/or coffee tip page shows
      if (
        selectedItem[i].id === "list_1_1" ||
        selectedItem[i].id === "list_1_2"
      ) {
        setNegativeList(false);
      }
      // else {
      //   setNegativeList(true);
      // }
      idsString += selectedItem[i].id + ",";
    }
    idsString = idsString.slice(0, -1);
    setListItemID(idsString);
  };

  const handleBackButtonPress = () => {
    // Remove the last item from the array
    setFatigueQuestionnaireResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses.pop();
      return updatedResponses;
    });
  };

  const submitHandler = async () => {
    const id = username;
    // Set the headers with the bearer token
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You may need to adjust the content type
    };
    // Create the request body
    const requestBody = {
      dateObserved: {
        type: "string",
        value: new Date().toISOString(),
      },
      answers: {
        type: "json",
        value: fatigueQuestionnaireResponses,
      },
    };
    //dynamically constract the url
    const panaceaFatigueQuestionnaireUrl = `https://iot-app.snap4city.org/orion-broker-panacea/v2/entities/PanaceaFatigueQuestionnaire-${id}/attrs?type=Sensor&elementid=PanaceaFatigueQuestionnaire-${id}`;
    // Send the PATCH request with headers
    fetch(panaceaFatigueQuestionnaireUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        console.log("Response:", response.status);
        if (response.status !== 400) {
          alert("Update", "Successfully sent questionnaire");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div style={styles.container}>
      {/* introduction page */}
      {!surePressed && (
        <div style={styles.container}>
          <h1 style={styles.header}>Hi {username}!</h1>
          <p style={styles.text}>
            Driver fatigue is an issue we need to face together.
          </p>
          <p style={styles.text}>
            Please help us understand how it affects you and take a minute to
            answer a few questions.
          </p>
          <div style={styles.buttonContainer}>
            <Button
              style={styles.button}
              onClick={() => {
                navigate("/profile");
              }}
            >
              No
            </Button>
            <Button style={styles.button} onClick={() => setSurePressed(true)}>
              Yes
            </Button>
          </div>
        </div>
      )}

      {/* first question */}
      {surePressed && !goToSecondQuestion && (
        <div style={styles.container}>
          <Question mode="emoji" mode2="normal" onEmojiPress={handleEmojiPress}>
            How problematic did you find fatigue this week? (single choice)
          </Question>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => {
                navigate("/profile");
              }}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                setFatigueQuestionnaireResponses((prevResponses) => [
                  ...prevResponses,
                  { answer: emojiID, question: "opinion_0" },
                ]);
                setGoToSecondQuestion(true);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {/* second question */}
      {goToSecondQuestion && !goToThirdQuestion && !goToFourthQuestion && (
        <div style={styles.container}>
          <Question
            mode="emoji"
            mode2="reverse"
            onEmojiPress={handleEmojiPress}
          >
            How satisfied were you with this week's shift? (single choice)
          </Question>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => {
                setGoToSecondQuestion(false);
                handleBackButtonPress();
              }}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setFatigueQuestionnaireResponses((prevResponses) => [
                  ...prevResponses,
                  { answer: emojiID, question: "opinion_1" },
                ]);
                negativeEmoji
                  ? setGoToThirdQuestion(true)
                  : setGoToFourthQuestion(true);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {/* third question */}
      {goToThirdQuestion && !goToFourthQuestion && (
        <div style={styles.container}>
          <Question
            mode="list"
            mode2="single"
            data={dataThird}
            onListItemPress={handleListItemPress}
          >
            What would you like to change the most? (single choice)
          </Question>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => {
                setGoToThirdQuestion(false);
                handleBackButtonPress();
              }}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setFatigueQuestionnaireResponses((prevResponses) => [
                  ...prevResponses,
                  { answer: listItemID, question: "list_0" },
                ]);
                setGoToFourthQuestion(true);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {/* fourth question */}
      {goToFourthQuestion && !showTips && !goToFifthQuestion && (
        <div style={styles.container}>
          <Question
            mode="list"
            mode2="multiple"
            data={dataFourth}
            onListItemPress={handleListItemsPress}
          >
            Which strategies did you use to combat fatigue? (multiple choice)
          </Question>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => {
                setGoToFourthQuestion(false);
                handleBackButtonPress();
              }}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setFatigueQuestionnaireResponses((prevResponses) => [
                  ...prevResponses,
                  { answer: listItemID, question: "list_1" },
                ]);
                !negativeList ? setGoToFifthQuestion(true) : setShowTips(true);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {/* tip page */}
      {showTips && !goToFifthQuestion && (
        <div style={styles.container}>
          <h1 style={styles.header}>Tip:</h1>
          <p style={styles.text}>
            Research shows that remedies other than caffeine and naps have
            limited effect on fatigue!
          </p>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => {
                setNegativeList(true);
                setShowTips(false);
                handleBackButtonPress();
              }}
            >
              Back
            </Button>
            <Button onClick={() => setGoToFifthQuestion(true)}>Next</Button>
          </div>
        </div>
      )}

      {/* fifth question */}
      {goToFifthQuestion && !goToSubmit && (
        <div style={styles.container}>
          <Question
            mode="list"
            mode2="single"
            data={dataFifth}
            onListItemPress={handleListItemPress}
          >
            What could you improve the most to feel more rested? (single choice)
          </Question>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => {
                setNegativeList(true);
                setGoToFifthQuestion(false);
                handleBackButtonPress();
              }}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setFatigueQuestionnaireResponses((prevResponses) => [
                  ...prevResponses,
                  { answer: listItemID, question: "list_2" },
                ]);
                setGoToSubmit(true);
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {/* submit to operator */}
      {goToSubmit && (
        <div style={styles.container}>
          <h1 style={styles.header}>Thank you for the answers.</h1>
          <p style={styles.text}>Here are some tips for you.</p>
          <p style={styles.text}>The operator will receive your answers.</p>
          <div style={styles.buttonContainer}>
            <Button
              onClick={() => {
                setGoToSubmit(false);
                submitHandler();
                navigate("/profile");
              }}
            >
              Submit to Operator
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FatigueQuestionnaireScreen;
