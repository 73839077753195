import SettingsScreen from "../components/screens/SettingsScreen";

const SettingsPage = () => {
  return (
    <div style={{ width: "100%", height: "100%", "overflow-y": "scroll" }}>
      <SettingsScreen />
    </div>
  );
};

export default SettingsPage;
