
const API_KEY = "AIzaSyAOxkz3uY2FjD9hZXdA3EGj0QKXSGDzKik";

export const resetPass= async(email)=>{
    const url= "https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key="+ API_KEY
    fetch(url, {
        method: "POST",
        body: JSON.stringify({
          requestType: 'PASSWORD_RESET',
          email:email
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(response => response.json())
}