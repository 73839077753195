import React, { useState, useEffect, useCallback } from "react";

let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  let adjExpirationTime;
  const currentTime = new Date().getTime();
  
  if (expirationTime) {
    adjExpirationTime = new Date(expirationTime).getTime();
  } else {
    adjExpirationTime = new Date().getTime();
  }

  const remainingTime = adjExpirationTime - currentTime;

  return remainingTime;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");

  if (!storedToken || !storedExpirationDate) {
    return null;
  }

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 0) {
    clearStorage();
    return null;
  }

  return { token: storedToken, duration: remainingTime };
};

const clearStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationTime");
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  const [token, setToken] = useState(tokenData ? tokenData.token : "");

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    console.log("LOGOUT");
    setToken("");
    clearStorage();
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, expirationTime) => {
    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expirationTime);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
   
    const checkExpiration = () => {
      console.log("token data=>", tokenData);
      
      if (tokenData) {
        const time= localStorage.getItem("expirationTime")
        const remainingTime = calculateRemainingTime(time);

        if (remainingTime > 0) {
          // localStorage.setItem("expirationTime", remainingTime);
          logoutTimer = setTimeout(() => {
            logoutHandler();
          }, remainingTime);
        } else {
          // Token has expired, perform logout
          logoutHandler();
        }
      }
    };

    checkExpiration(); // Initial check

    // Set up periodic checks every minute
    const interval = setInterval(() => {
      checkExpiration();
    }, 60000); // Check every minute

    // Clear the interval if the component unmounts
    return () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
      clearInterval(interval);
    };
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
