// import { useState } from "react";
// import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
import Timer from "../UI/Timer";

const StressManagementTimerScreen = () => {
  const styles = {
    container: {
        display: 'flex',
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonContainer: {
      width: "100%",
      height:'30vh',
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    button: {
      marginVertical: 8,
      padding: 4,
    },
    text: {
      fontSize: 24,
      fontWeight: "bold",
      color: "#337c75",
      marginLeft: 24,
      marginRight: 24,
      marginTop: 16,
      marginBottom: 16,
      textAlign: "justify",
    },
    sectionStyle: {
      alignItems: "center",
      justifyContent: "center",
    },
    input: {
      padding: "8px 16px",
      backgroundColor: "white",
      borderRadius: 4,
      borderColor: "#337c75",
      borderWidth: 1,
      textAlign: "center",
      margin: 16,
      color: "#337c75",
      fontWeight: "bold",
      fontSize: 18,
    },
  };

//   const options = {
//     container: {
//       backgroundColor: "#337c75",
//       padding: 5,
//       borderRadius: 5,
//       width: "100%",
//       alignItems: "center",
//     },
//     text: {
//       fontSize: 36,
//       color: "white",
//       textAlign: "center",
//     },
//   };

  const navigate = useNavigate();


  const handleFinish = () => {
    alert(
      "Timer finished\nCheck your stress level and see if you need more time."
    );
    navigate("/stress_relax");
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.text}>Set Timer</h2>
      <div style={styles.sectionStyle}>
          <Timer initialDuration={60} onTimerFinish={handleFinish} />
      </div>
    </div>
  );
};

export default StressManagementTimerScreen;
