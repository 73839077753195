import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { GiExitDoor } from "react-icons/gi";
import { FaRegBell, FaRegEnvelope } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import classes from "./MainNavigation.module.css";
import NotifContext from "../../store/notif-context";
import { Notify } from "../../constants/notify";
import { Alerts } from "../../constants/alerts";

const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  const ntfCtx = useContext(NotifContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const isLoggedIn = authCtx.isLoggedIn;

  const menuHandler = () => {
    setOpen(!open);
  };
  const logoutHandler = () => {
    Notify.length=0
    Alerts.length=0
    authCtx.logout();
    navigate("/", { replace: true });
  };
  return (
    <header className={classes.header}>
      <Link to="/">
        <div className={classes.logo}>
          <img
            className={classes.headerImg}
            src={require("../../assets/logo_header.png")}
            alt="App logo"
          />
        </div>
      </Link>
      <nav>
        <ul>
          {/* {isLoggedIn && (
            <li>
              <button
                onClick={() => {
                  ntfCtx.msgReceivedHandler(false);
                  navigate("/contact");
                }}
              >
                <FaRegEnvelope
                  size={24}
                  color={ntfCtx.msgReceived ? "orange" : "white"}
                />
              </button>
            </li>
          )} */}
          {isLoggedIn && (
            <li>
              <button
                onClick={() => {
                  navigate("/alerts&notifications");
                }}
              >
                <FaRegBell size={24} />
              </button>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button
                onClick={() => {
                  navigate("/settings");
                }}
              >
                <FiSettings size={24} />
              </button>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button onClick={logoutHandler}>
                <GiExitDoor size={24} />
              </button>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button onClick={menuHandler}>
                <img
                  className={classes.profilePic}
                  src={require("../../images/profile_picture.png")}
                  alt="profile pic"
                />
              </button>
              {open && (
                <ul
                  className={classes.dropdownmenu}
                  onMouseLeave={() => {
                    setOpen(!open);
                  }}
                >
                  <li
                    className={classes.dropdownoption}
                    onClick={() => {
                      navigate("/settings");
                      setOpen(!open);
                    }}
                  >
                    Settings
                  </li>
                  <li className={classes.dropdownoption}>Privacy Disclaimer</li>
                  <li
                    className={classes.dropdownoption}
                    onClick={logoutHandler}
                  >
                    Logout
                  </li>
                </ul>
              )}
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default MainNavigation;
