import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import classes from "./AuthForm.module.css";
import LoadingSpinner from "../UI/LoadingSpinner";

// const API_KEY = "AIzaSyAOxkz3uY2FjD9hZXdA3EGj0QKXSGDzKik";

const AuthForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    // optional: Add validation
    const nameIsValid =
      name.length > 3 && !name.includes("@") && !name.includes(" "); // add validity for @ and space in username during registration
    const emailIsValid = email.includes("@");
    const passwordIsValid = password.length > 6;

    setIsLoading(true);
    let url;
    let reqBody;
    let reqHeaders;
    if (isLogin) {
      url =
        "https://www.snap4city.org/auth/realms/master/protocol/openid-connect/token";
      reqBody = `grant_type=password&client_id=panacea-tool&username=${name}&password=${password}`;

      reqHeaders = { "Content-Type": "application/x-www-form-urlencoded" };
    } else {
      url = "https://www.snap4city.org/drupal/api/user/register";
      reqBody = JSON.stringify({
        name: name,
        mail: email,
        legal_accept: "true",
        "extras-1": "true",
        "extras-2": "true",
        og_user_node: { und: [{ default: "722" }] },
      });
      reqHeaders = { "Content-Type": "application/json" };
    }
    if (
      (!isLogin && emailIsValid && nameIsValid) ||
      (isLogin && nameIsValid && passwordIsValid)
    ) {
      fetch(url, {
        method: "POST",
        body: reqBody,
        headers: reqHeaders,
      })
        .then((res) => {
          // setIsLoading(false);
          if (res.ok || res.statusCode ==="201") {
            if (
              res.statusText === "OK" &&
              res.url === "https://www.snap4city.org/drupal/api/user/register"
            ) {
              alert(
                "Registration Success. A confirmation email has been send to you from Snap4City (smtp@snap4city.org). Please check your registered email accound (check spam folder too) to access it and follow the instractions."
              );
            } else {
              return res.json();
            }
          } else {
            if (res.status === 406) {
              alert(res.statusText);
            } else {
              return res.json().then((data) => {
                let errorMessage = "Authentication failed!";
                alert("Invalid input. Plase check your credentials!");
                throw new Error(errorMessage);
              });
            }
          }
        })
        .then((data) => {
          const expirationTime = new Date(
            new Date().getTime() + +data.expires_in * 1000
          );
          console.log(expirationTime)
          authCtx.login(data.access_token, expirationTime.toISOString());
          if (isLogin) {
            setIsLoading(true);
            const jsonObj = {
              id: name,
              type: "Profile",
              role: "Driver",
              country: "",
              yearOfBirth: "",
              nickname: name,
            };
            const stringObj = JSON.stringify(jsonObj);
            const headers = {
              Authorization: `Bearer ${data.access_token}`,
              "Content-Type": "application/json", // You may need to adjust the content type
            };
            const participantGetUrl =
              "https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaParticipantProfile-" +
              name;
            fetch(participantGetUrl, { headers })
              .then((response) => response.json())
              .then((data) => {
                // console.log("DATA=>",data)
                global.loggedInUserName =
                  data.realtime.results.bindings[0].nickname.value;
                global.loggedInUserRole =
                  data.realtime.results.bindings[0].role.value;
                global.loggedInUserCountry =
                  data.realtime.results.bindings[0].country.value;
                // console.log(
                //   global.loggedInUserName,
                //   global.loggedInUserRole,
                //   global.loggedInUserCountry
                // );
                if (data.realtime.results.bindings[0].role.value === "Driver") {
                  // console.log("DRIVER");
                  navigate("/profile", { replace: true });
                } else {
                  // console.log("OPERATOR");
                  navigate("/drivers", { replace: true });
                }
                setIsLoading(false);
              })
              .catch((error) => {
                console.log(error);
              });
            // })
            // .catch((err) => {
            //   alert(err.message);
            // });
          }
        })
        .catch((err) => {
          // alert(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  //for going to the forgot password screen
  const forgotPasswordHandler = () => {
    navigate("/forgotpass");
  };

  return (
    <div className={classes.modal}>
      <section className={classes.auth}>
        <h1>{isLogin ? "Login" : "Sign Up"}</h1>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor="email">Username</label>
            <input
              type="text"
              id="name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {!isLogin && (
            <div className={classes.control}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          )}
          {isLogin && (
            <div className={classes.control}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          )}
          {/* {!isLogin && (
            <div className={classes.control}>
              <label htmlFor="email">Confirm Password</label>
              <input
                type="text"
                id="name"
                value={retypePassword}
                required
                onChange={(e) => setRetypePassword(e.target.value)}
              />
            </div>
          )} */}
          {/* {!isLogin && (
            <div className={classes.control}>
              <label htmlFor="email">Year of Dirth</label> */}
          {/* <input
                type="date"
                id="date"
                placeholder="Select date"
                value={birthday}
                required
                onChange={(e) => setBirthday(e.target.value)}
              /> */}
          {/* <Yearpicker />
            </div>
          )} */}
          {/* {!isLogin && (
            <div className={classes.control}>
              <label htmlFor="email">Role</label>
              <select
                defaultValue=""
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="" disabled></option>
                <option value="driver">Driver</option>
                <option value="rider">Rider</option>
                <option value="operator">Operator</option>
                <option value="admin">Administrator</option>
                <option value="enfrocer">Enforcer</option>
                <option value="ccsspecialist">Countermeasure Specialist</option>
              </select>
            </div>
          )} */}
          <div className={classes.actions}>
            {!isLoading && <button>{isLogin ? "Log in" : "Sign up"}</button>}
            {isLoading && <LoadingSpinner />}
            {isLogin ? (
              <div
                style={{
                  "margin-top": "20px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <button
                  className={classes.toggle}
                  // onClick={forgotPasswordHandler}
                >
                  <a href="https://www.snap4city.org/auth/realms/master/login-actions/reset-credentials?client_id=php-dashboard-builder&tab_id=rG8GyxbM9jw">
                    Forgot Password?
                  </a>
                </button>

                <p className={classes.flat_text}>
                  Don't have an account?
                  <button
                    type="button"
                    className={classes.toggle}
                    onClick={switchAuthModeHandler}
                  >
                    Sign up
                  </button>
                </p>
              </div>
            ) : (
              <div
                style={{
                  "margin-top": "20px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p className={classes.flat_text}>
                  Already have an account?{" "}
                  <button
                    type="button"
                    className={classes.toggle}
                    onClick={switchAuthModeHandler}
                  >
                    {" "}
                    Log in instead
                  </button>
                </p>
              </div>
            )}
          </div>
        </form>
      </section>
    </div>
  );
};

export default AuthForm;
