import styles from './ToggleButton.module.css'

function ToggleButton({isChecked, onToggle}) {

  return (
      <label className={styles.switch}>
        <input type="checkbox" checked={isChecked} onChange={onToggle} />
        <span className={styles.slider}></span>
      </label>
  );
}

export default ToggleButton;