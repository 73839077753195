import { Fragment } from "react";
import styles from "./InfoScreen.module.css";
import webInfoImg from "../../assets/panaceapp_title.png";
import Button from "../UI/Button";
import { Link, useNavigate } from "react-router-dom";

const InfoScreen = () => {
  const navigate = useNavigate();

  const onEnterHandler = () => {
    navigate("/auth", { replace: true });
  };

  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles["main-image"]}>
            <img className={styles.image} src={webInfoImg} />
          </div>
          <div className={styles.text}>
            <p>
              The <strong>PANACEA project</strong> is creating a 24-hour
              holistic support system to monitor and enhance the wellbeing of
              commercial drivers.
            </p>
            <br />
            <p>
              As a <strong>driver/rider</strong>, this app allows you to create
              a profile and monitor your state whilst shift or not. As an{" "}
              <strong>operator</strong>, you' ll be able to monitor the drivers'
              ability and state.
            </p>
          </div>
          <div className={styles.infoContainer}>
            <p className={styles.infoText}>
              For more information, you may visit:{" "}
              <a href="https://panacea-project.eu/">
                https://panacea-project.eu/
              </a>
            </p>
            <p className={styles.infoText}>
              <Link to="/privacy_disclaimer">
                Privacy Disclaimer
              </Link>
            </p>
          </div>
          <div className={styles.buttonContainer}>
            <Button className={styles.buttons} onClick={onEnterHandler}>
              Enter
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InfoScreen;
