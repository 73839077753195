import styles from "./ProgressTopBar.module.css";
import ProgressBar from "@ramonak/react-progress-bar";

const ProgressTopBar = () => {
  return (
    <ProgressBar
      className={styles.wrapper}
      barContainerClassName={styles.barContainer}
      completedClassName={styles.barCompleted}
      // labelClassName={styles.barLabel}
      customLabel={
        <span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              bottom: "68%",
              position: "relative",
            }}
          >
            <img
              alt="Road"
              style={{ width: "60px", height: "40px" }}
              src={require("../../images/ico_road_web.png")}
            />
            {/* <p>Time elapsed</p> */}
          </div>
        </span>
      }
      completed={30}
    />
  );
};

export default ProgressTopBar;
