import styles from "./ProfileCard.module.css";

const ProfileCard = ({ title, onSystem, value, decision }) => {
  return (
    <div className={styles.innerContainer}>
      <div
        className={
          onSystem ? styles.titleContainerOn : styles.titleContainerOff
        }
      >
        <p className={styles.title}>{title}</p>
      </div>
      <div
        className={onSystem ? styles.textContainerOn : styles.textContainerOff}
      >
        <p className={styles.text}>
          System:
          {onSystem ? (
            <span className={styles.textBoldOn}>ON</span>
          ) : (
            <span className={styles.textBoldOff}>OFF</span>
          )}
        </p>
        <p className={styles.text}>
          State (level):{" "}
          {onSystem ? (
            <span
              style={{
                fontWeight: "bold",
                color:
                  decision === "pass" || decision === "Fit to Drive"
                    ? "#3ab54a"
                    : "#b53a3a",
              }}
            >
              {value}
            </span>
          ) : (
            <span className={styles.textBoldOff}>N/A</span>
          )}
        </p>
        <p className={styles.text}>
          Decision:{" "}
          {onSystem ? (
            <span
              style={{
                fontWeight: "bold",
                color:
                  decision === "pass" || decision === "Fit to Drive"
                    ? "#3ab54a"
                    : "#b53a3a",
              }}
            >
              {decision}
            </span>
          ) : (
            <span className={styles.textBoldOff}>N/A</span>
          )}
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
