import { useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import styles from "./ActivityFeedScreen.module.css"

const ActivityFeedScreen= ()=>{

      const navigate = useNavigate();
    return <div className={styles.container}>
    <div className={styles.buttonContainer}>
      <Button
        style={styles.button}
        onClick={() => {
            navigate("/fatigue_report");
          }}
      >
        Fatigue Report
      </Button>
      <Button
        style={styles.button}
        onClick={() => {
            navigate("/fatigue_questionnaire");
          }}
      >
        Fatigue Questionnaire
      </Button>
      <Button
        style={styles.button}
        onClick={() => {
          navigate("/stress_management");
        }}
      >
        Stress Management
      </Button>
    </div>
  </div>
}

export default ActivityFeedScreen