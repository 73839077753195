import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
import BarChart from "../UI/BarChart";

const StressManagementResultsScreen = () => {
  const navigate = useNavigate();
  let ableToDrive;

  const styles = {
    outerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    container: {
      display: 'flex',
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
    //   height: "30vh",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    header: {
      textAlign: "center",
      fontSize: 32,
      fontWeight: "bold",
      color: "#337c75",
      marginTop: 16,
      marginBottom: 16,
    },
  };

  const levelBefore = 5;
  const levelAfter = 5;

  const getLevelArray = (level) => {
    const maxLevel = 5;
    const levelArray = Array(maxLevel).fill(0); // Initialize the array with zeroes

    if (level > 0 && level <= maxLevel) {
      for (let i = 0; i < level; i++) {
        levelArray[i] = i + 1; // Set the specified level value and preceding values
      }
    }

    return levelArray.map((level) => ({ level }));
  };

  const dataBefore = getLevelArray(levelBefore);
  const dataAfter = getLevelArray(levelAfter);

  for (let i = 0; i < 5; i++) {
    if (dataAfter[i].level < dataBefore[i].level) {
      ableToDrive = "Good to go!";
    } else {
      ableToDrive = "Maybe wait a little longer.";
    }
  }

  return (
    <div style={styles.outerContainer}>
      <p style={styles.header}>Am I good to go?</p>
      <div style={styles.container}>
        <BarChart data={dataBefore}>Before</BarChart>
        <BarChart data={dataAfter}>After</BarChart>
      </div>
      <p style={styles.header}>{ableToDrive}</p>
      <div style={styles.buttonContainer}>
        <Button
          onClick={() => {
            navigate("/stress_management");
          }}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default StressManagementResultsScreen;
