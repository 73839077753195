import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";

const StressManagementScreen = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      height:"30vh",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    button: {
      marginTop: 8,
      marginBottom: 8,
      padding: 4,
    },
    text: {
      fontSize: 24,
      fontWeight: "bold",
      color: "#337c75",
      marginLeft: 24,
      marginRight: 24,
      marginTop: 16,
      marginBottom: 16,
      textAlign: "justify",
    },
  };

  const username = global.loggedInUserName;
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <p style={styles.text}>
        You seem stressed, {username}. This could present a safety risk.
      </p>
      <div style={styles.buttonContainer}>
        <Button
          style={styles.button}
          onClick={() => {
            navigate("/stress_relax");
          }}
        >
          Take a break
        </Button>
        <Button
          style={styles.button}
          onClick={() => {
            navigate("/profile");
          }}
        >
          No thank you
        </Button>
      </div>
    </div>
  );
};

export default StressManagementScreen;
