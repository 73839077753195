import StressManagementScreen from "../components/screens/StressManagementScreen";

const StressManagementPage = () => {
  return <div style={{ width: "100%", height: "100%" }}>
  
  <StressManagementScreen/>
</div>
};

export default StressManagementPage;
