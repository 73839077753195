import { useState } from "react";
import styles from "./ManagingUsersScreen.module.css";

const ManagingUsersScreen = () => {
  const [submitted, setSubmitted]= useState(false);
  const [panUsername, setPanUsername] = useState("");
  const [snapUsername, setSnapUsername] = useState("");
  const [role, setRole] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();
    setSubmitted(!submitted);
    if(submitted){
      alert("Successfully managed participant.");
    }else{
      alert("Error while managing participant.");
    }
    console.log("SUMBITTING");

  };

  return (
    <div className={styles.modal}>
      <section className={styles.auth}>
        <h1>Manage Users</h1>
        <form onSubmit={submitHandler}>
          <div className={styles.control}>
            <label htmlFor="panacea_username">Panacea Username</label>
            <input
              type="text"
              id="pan_username"
              value={panUsername}
              required
              onChange={(e) => setPanUsername(e.target.value)}
            />
          </div>
          <div className={styles.control}>
            <label htmlFor="snap4city_username">Snap4City Username</label>
            <input
              type="email"
              id="snap_username"
              value={snapUsername}
              required
              onChange={(e) => setSnapUsername(e.target.value)}
            />
          </div>
          <div className={styles.control}>
            <label htmlFor="role">Role</label>
            <select
              defaultValue=""
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="" disabled></option>
              <option value="driver">Driver</option>
              <option value="rider">Rider</option>
              <option value="operator">Operator</option>
              <option value="admin">Administrator</option>
              <option value="enfrocer">Enforcer</option>
              <option value="ccsspecialist">Countermeasure Specialist</option>
            </select>
          </div>
          <div className={styles.actions}>
            <button>Submit</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ManagingUsersScreen;
