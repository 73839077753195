export const DUMMY_USERS = [
  {
    name: "name1",
    surname: "surname1",
    city: "Athens",
    country: "Greece",
    role: "Driver",
    shiftStatus: "ON",
  },
  {
    name: "name2",
    surname: "surname2",
    city: "Athens",
    country: "Greece",
    role: "Driver",
    shiftStatus: "ON",
  },
  {
    name: "name3",
    surname: "surname3",
    city: "Athens",
    country: "Greece",
    role: "Driver",
    shiftStatus: "OFF",
  },
  {
    name: "name4",
    surname: "surname4",
    city: "Athens",
    country: "Greece",
    role: "Driver",
    shiftStatus: "PRE",
  },
];

export const APP_ID = "44ED489C-34FD-4FD8-8EA1-3F38CFE56CC7";
