import React from "react";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";

const PrivacyDisclaimer = () => {
  const navigate = useNavigate();

  const containerStyle = {
    margin: "0cm",
    fontSize: "13px",
    fontFamily: "Calibri, sans-serif",
    borderBottom: "solid windowtext 1.0pt",
    padding: "0cm 0cm 1.0pt 0cm",
  };

  const headingStyle = {
    margin: "0cm",
    marginRight: "0cm",
    marginLeft: "0cm",
    fontSize: "13px",
    fontFamily: "Calibri, sans-serif",
    textAlign: "center",
    border: "none",
    padding: "0cm",
    marginTop: "0cm",
    marginBottom: "15.0pt",
  };

  const paragraphStyle = {
    margin: "0cm",
    marginRight: "0cm",
    marginLeft: "0cm",
    fontSize: "13px",
    fontFamily: "Calibri, sans-serif",
    textAlign: "justify",
    marginTop: "0cm",
    marginBottom: "15.0pt",
  };

  const strongStyle = {
    fontSize: "15px",
    fontFamily: "Calibri, sans-serif",
  };

  return (
    <div style={containerStyle}>
      <p style={headingStyle}>
        <strong>
          <span style={strongStyle}>
            Privacy Disclaimer for PANACEA Web and Mobile Applications
          </span>
        </strong>
      </p>
      <p style={paragraphStyle}>
        <em>
          <span style={strongStyle}>Last Updated: October 04, 2023</span>
        </em>
      </p>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>1. Introduction:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;At PANACEA, we are dedicated to ensuring the privacy and data
          protection rights of our users, in compliance with the General Data
          Protection Regulation (GDPR) and other national applicable data
          protection laws. This GDPR Compliant Privacy Disclaimer outlines how
          we collect, use, and protect personal data when you use our web and
          mobile applications as an operator, professional driver, or rider.
          Please carefully review this disclaimer to understand your rights and
          our data processing practices.
        </span>
      </p>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>2. Data Controller:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;PANACEA, as the data controller, is responsible for processing
          your personal data as described in this Privacy Disclaimer. If you
          have any questions or concerns regarding your data, you can contact us
          at: touliouk@certh.gr.
        </span>
      </p>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>3. Types of Data We Collect:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;We collect various types of data from users of our web and
          mobile applications, including but not limited to:
        </span>
      </p>
      <ul
        style={{ ...paragraphStyle, listStyleType: "disc", marginLeft: "26px" }}
      >
        <li>
          <strong>
            <span style={strongStyle}>Personal Information:</span>
          </strong>
          <span style={strongStyle}>
            &nbsp;This includes your name, contact details, and other
            identification data necessary for registration and account creation.
          </span>
        </li>
        <li>
          <strong>
            <span style={strongStyle}>Usage Data:</span>
          </strong>
          <span style={strongStyle}>
            &nbsp;We collect data related to your interactions with our
            applications, such as the use of specific features, actions taken,
            and device information.
          </span>
        </li>
        <li>
          <strong>
            <span style={strongStyle}>Driver/Rider Information:</span>
          </strong>
          <span style={strongStyle}>
            &nbsp;If you are an operator, we collect information related to the
            drivers and riders under your care, including health and well-being
            data.
          </span>
        </li>
        <li>
          <strong>
            <span style={strongStyle}>Location Data:</span>
          </strong>
          <span style={{ ...strongStyle, fontSize: "11.0pt" }}>
            &nbsp;We may collect location data primarily for safety and
            operational purposes, with your explicit consent.
          </span>
        </li>
      </ul>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>4. Legal Basis for Processing:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;We process your personal data based on one or more legal
          grounds, including your consent, the necessity of processing for the
          performance of a contract, compliance with legal obligations, and
          legitimate interests pursued by us or third parties.
        </span>
      </p>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>5. How We Use Your Data:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;We use your data for the following purposes:
        </span>
      </p>
      <ul
        style={{ ...paragraphStyle, listStyleType: "disc", marginLeft: "26px" }}
      >
        <li>
          <strong>
            <span style={strongStyle}>Operator Monitoring:</span>
          </strong>
          <span style={{ ...strongStyle, fontSize: "11.0pt" }}>
            &nbsp;If you are an operator, we use your data to monitor the
            well-being and activities of drivers and riders under your
            responsibility.
          </span>
        </li>
        <li>
          <strong>
            <span style={strongStyle}>Driver/Rider Safety:</span>
          </strong>
          <span style={{ ...strongStyle, fontSize: "11.0pt" }}>
            &nbsp;If you are a driver or rider, we use your data to ensure your
            safety during operations.
          </span>
        </li>
        <li>
          <strong>
            <span style={strongStyle}>Communication:</span>
          </strong>
          <span style={strongStyle}>
            &nbsp;We may use your contact information to send alerts,
            notifications, and important updates related to your role and
            safety.
          </span>
        </li>
        <li>
          <strong>
            <span style={strongStyle}>Performance Improvement:</span>
          </strong>
          <span style={strongStyle}>
            &nbsp;We analyze usage data to enhance the functionality and
            performance of our applications.
          </span>
        </li>
      </ul>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>6. Data Sharing:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;We will NOT share your data with third parties, but only in
          accordance with this Privacy Disclaimer and applicable laws. Your data
          may be shared with:
        </span>
      </p>
      <div style={containerStyle}>
        <ul
          style={{
            ...paragraphStyle,
            listStyleType: "disc",
            marginLeft: "26px",
          }}
        >
          <li style={paragraphStyle}>
            <strong>
              <span style={strongStyle}>Authorized Personnel:</span>
            </strong>
            <span style={{ ...strongStyle, fontSize: "11.0pt" }}>
              &nbsp;Operator information may be accessible to authorized
              personnel for monitoring and safety purposes.
            </span>
          </li>
        </ul>
      </div>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>7. International Data Transfers:</span>
        </strong>
        <span style={strongStyle}>&nbsp;Your data&nbsp;</span>
        <span style={strongStyle}>will NOT</span>
        <span style={strongStyle}>
          &nbsp;be transferred and processed in countries outside the European
          Economic Area (EEA) and or outside the PANACEA Consortium. You may
          find more information about the project and the partners in the
          PANACEA website:&nbsp;
        </span>
        <a href="https://panacea-project.eu/">
          <span style={strongStyle}>https://panacea-project.eu/</span>
        </a>
        <span style={strongStyle}>
          &nbsp;Adequate safeguards are in place to protect your data as
          required by GDPR.
        </span>
      </p>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>8. Data Security:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;We implement robust security measures to protect your data from
          unauthorized access, disclosure, alteration, or destruction. However,
          no system can be entirely secure, and you use our applications at your
          own risk.
        </span>
      </p>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>9. Your Rights:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;Under GDPR, you have specific rights related to your personal
          data, including the right to access, rectify, erase, restrict
          processing, and data portability. You also have the right to object to
          processing based on legitimate interests or for direct marketing
          purposes.
        </span>
      </p>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>
            10. Updates to This Privacy Disclaimer:
          </span>
        </strong>
        <span style={strongStyle}>
          &nbsp;We may update this Privacy Disclaimer to reflect changes in our
          practices and legal requirements. Any updates will be published on our
          website.
        </span>
      </p>
      <p style={paragraphStyle}>
        <strong>
          <span style={strongStyle}>11. Contact Us:</span>
        </strong>
        <span style={strongStyle}>
          &nbsp;If you have any questions or concerns about your privacy, data
          protection, or this Privacy Disclaimer, please contact us at:
          touliouk@certh.gr
        </span>
      </p>
      <p style={paragraphStyle}>
        <span style={strongStyle}>
          By using our web and mobile applications, you consent to the terms
          outlined in this GDPR Compliant Privacy Disclaimer. Your continued use
          of our services constitutes acceptance of any updates or modifications
          to this disclaimer.
        </span>
      </p>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Button onClick={() => navigate("/")}>Back</Button>
      </div>
    </div>
  );
};

export default PrivacyDisclaimer;
