import { useContext, useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import Button from "../UI/Button";
import styles from "./DriversShiftScreen.module.css";
import txtA from "../../assets/UCA.txt";
import txtB from "../../assets/UCB.txt";
import txtC from "../../assets/UCC.txt";
import AuthContext from "../../store/auth-context";

const DriversShiftScreen = () => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const [useCase, setUseCase] = useState();
  const [lines, setLines] = useState([]);
  const [participant, setParticipant] = useState();
  const [shift, setShift] = useState();
  const [startingTime, setStartingTime] = useState();
  const [endingTime, setEndingTime] = useState();

  const submitHandler = () => {
    console.log("SHIFT SUBMITTED");
    // console.log(participant.replace(/\r/g, ""));
    const jsonObj = {
      dateObserved: {
        type: "string",
        value: new Date().toISOString(),
      },
      isOnDuty: {
        type: "integer",
        value: shift,
      },
      onDutyStarted: {
        type: "string",
        value: startingTime.toISOString(),
      },
      onDutyFinished: {
        type: "string",
        value: endingTime.toISOString(),
      },
      interruptions: {
        type: "json",
        value: {},
      },
      participantID: {
        type: "string",
        value: participant.replace(/\r/g, ""),
      },
      // nickname: {
      //   type: "string",
      //   value: participant.replace(/\r/g, ""),
      // },
    };
    // console.log(jsonObj);
    const stringObj = JSON.stringify(jsonObj);
    const setShiftUrl = `https://iot-app.snap4city.org/orion-broker-panacea/v2/entities/PanaceaShift-${participant}/attrs?type=Sensor&elementid=PanaceaShift-${participant}`;
    fetch(setShiftUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
      body: stringObj,
    })
      .then((response) => {
        console.log("SETSHIFT");
        if (response.status === 204) {
          alert("Successfully updated participant shift.");
        }
      })
      .catch((err) => {
        alert("Error. Participant's shift could not be updated.");
        console.log(err.message);
      });
  };

  const onUseCaseHandler = (e) => {
    setUseCase(e.target.value);
  };

  useEffect(() => {
    // var lines = [];
    // console.log("HERE")
    if (useCase === "UCA") {
      fetch(txtA)
        .then((response) => response.text())
        .then((data) => {
          const lines = data.split("\n");
          setLines(lines);
        })
        .catch((error) => console.error(error));
    } else if (useCase === "UCB") {
      fetch(txtB)
        .then((response) => response.text())
        .then((data) => {
          const lines = data.split("\n");
          setLines(lines);
        })
        .catch((error) => console.error(error));
    } else if (useCase === "UCC") {
      fetch(txtC)
        .then((response) => response.text())
        .then((data) => {
          const lines = data.split("\n");
          setLines(lines);
        })
        .catch((error) => console.error(error));
    }
  }, [useCase]);

  return (
    <div className={styles.innerContainer}>
      <h1>Driver's Shift</h1>
      <div className={styles.control}>
        <label htmlFor="use_case">Use Case</label>
        <select
          onChange={(e) => {
            setUseCase(e.target.value);
          }}
        >
          <option value="Select a use case" selected>
            Select a use case
          </option>
          <option
            value="UCA"
            onSelect={(e) => {
              onUseCaseHandler(e);
            }}
          >
            UCA
          </option>
          <option
            value="UCB"
            onSelect={(e) => {
              onUseCaseHandler(e);
            }}
          >
            UCB
          </option>
          <option
            value="UCC"
            onSelect={(e) => {
              onUseCaseHandler(e);
            }}
          >
            UCC
          </option>
        </select>
      </div>
      <div className={styles.control}>
        <label htmlFor="participant">Participants</label>
        <select
          onChange={(e) => {
            setParticipant(e.target.value);
          }}
        >
          <option value="Select a participant" selected>
            Select a participant
          </option>
          {lines.map((line, index) => (
            <option key={index} value={line}>
              {line}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.control}>
        <label htmlFor="shift">Shift</label>
        <select
          onChange={(e) => {
            if (e.target.value === "offDuty") {
              setShift(0);
            } else if (e.target.value === "onDuty") {
              setShift(1);
            } else if (e.target.value === "preDriving") {
              setShift(2);
            } else if (e.target.value === "onSite") {
              setShift(3);
            } else {
              setShift(4);
            }
          }}
        >
          <option value="Select shift" selected>
            Select shift
          </option>
          <option value="offDuty">offDuty</option>
          <option value="onDuty">onDuty</option>
          <option value="preDriving">preDriving</option>
          <option value="onSite">onSite</option>
          <option value="onLunch">onLunch</option>
        </select>
      </div>
      <div className={styles.control}>
        <label htmlFor="starting_time">Starting time</label>
        {/* https://www.npmjs.com/package/react-datetime-picker */}
        <DateTimePicker
          className={styles["react-datetime-picker"]}
          onChange={setStartingTime}
          value={startingTime}
          format="dd/MM/y HH:mm"
        />
      </div>
      <div className={styles.control}>
        <label htmlFor="ending_time">Ending time</label>
        <DateTimePicker
          wrapperClassName={styles.dateTimeWrapper}
          className={styles["react-datetime-picker"]}
          onChange={setEndingTime}
          value={endingTime}
          format="dd/MM/y HH:mm"
        />
      </div>

      <div className={styles.buttons}>
        <Button onClick={submitHandler}>Submit</Button>
      </div>
    </div>
  );
};

export default DriversShiftScreen;
