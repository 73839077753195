import { useState, useEffect } from "react";
import Button from "./Button";

const Timer = ({ initialDuration, onTimerFinish }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      width: "100%",
      height: "30vh",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
      marginTop: 16,
      marginBottom: 16,
    },
    button: {
      marginVertical: 8,
      padding: 4,
    },
    text: {
      fontSize: 24,
      fontWeight: "bold",
      color: "#337c75",
      marginLeft: 24,
      marginRight: 24,
      marginTop: 16,
      marginBottom: 16,
      textAlign: "justify",
    },
    sectionStyle: {
      alignItems: "center",
      justifyContent: "center",
    },
    input: {
      padding: "8px 16px",
      backgroundColor: "white",
      borderRadius: 4,
      borderColor: "#337c75",
      borderWidth: 1,
      textAlign: "center",
      margin: 16,
      color: "#337c75",
      fontWeight: "bold",
      fontSize: 18,
    },
  };

  const [duration, setDuration] = useState(initialDuration);
  const [timeRemaining, setTimeRemaining] = useState(initialDuration);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let timer = null;

    if (isRunning && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      setIsRunning(false);
      onTimerFinish();
    }

    return () => {
      clearInterval(timer);
    };
  }, [isRunning, timeRemaining, onTimerFinish]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleStop = () => {
    setIsRunning(false);
  };

//   const handleReset = () => {
//     setIsRunning(false);
//     setTimeRemaining(duration);
//   };

  const handleDurationChange = (e) => {
    const newDuration = parseInt(e.target.value, 10);

    setDuration(newDuration);
    setTimeRemaining(newDuration);
  };

  return (
    <div style={styles.container}>
      <div style={styles.text}>{formatTime(timeRemaining)}</div>
      <div>
        <input
          style={styles.input}
          type="number"
          value={duration}
          onChange={handleDurationChange}
        />
        <div style={styles.buttonContainer}>
          <Button
            style={styles.button}
            onClick={handleStart}
            disabled={isRunning}
          >
            Start
          </Button>
          <Button onClick={handleStop} disabled={!isRunning}>
            Stop
          </Button>
          {/* <Button onClick={handleReset} disabled={isRunning}>
          Reset
        </Button>*/}
        </div>
      </div>
    </div>
  );
};

export default Timer;
