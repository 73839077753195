import { useLocation } from "react-router-dom";
import MyProfileScreen from "../components/screens/MyProfileScreen";
import Notifications from "../components/UI/Notifications";
import Button from "../components/UI/Button";
import { useNavigate } from "react-router-dom";

const MyProfilePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  if (global.loggedInUserRole === "Operator") {
    global.selectedDriver = location.state.name;
  }

  return (
    <div style={{ width: "100%", height: "100%"}}>
      {global.loggedInUserRole === "Operator" && (
        <div
          style={{
            height: "2%",
            textAlign: "center",
            fontSize: "24px",
            fontWeight: "bold",
            color: "#337c75",
            padding: "8px",
          }}
        >
          Driver: {location.state.name}
        </div>
      )}
      <MyProfileScreen
        name={
          global.loggedInUserRole === "Operator" ? location.state.name : null
        }
      />
      <Notifications role={global.loggedInUserRole} />
      {global.loggedInUserRole === "Operator" && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width:"100%"
          }}
        >
          <Button
            onClick={() => {
              navigate("/countermeasures");
            }}
          >
            <p style={{fontSize: 18}}>Countermeasures</p>
          </Button>
          <Button
            onClick={() => {
              navigate("/fatigue_report");
            }}
          >
            <p style={{fontSize: 18}}>Fatigue Report</p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default MyProfilePage;
