import styles from "./SideBarButton.module.css";

const SideBarButton = ({children, onClick}) => {
  return (
    <div className={styles.buttonContainer}>
      <button className={styles.customButton} onClick={onClick}>{children}</button>
    </div>
  );
};

export default SideBarButton;
