import { useContext, useEffect, useState } from "react";
import ProgressTopBar from "../UI/ProgressTopBar";
import styles from "./FeedbackScreen.module.css";
import { CiPaperplane } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";

const FeedbackScreen = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const username = global.loggedInUserName;

  useEffect(() => {
    const getParticipant = async () => {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // You may need to adjust the content type
      };
      const getFeedbackUrl =
        "https://www.snap4city.org/superservicemap/api/v1/?serviceUri=http://www.disit.org/km4city/resource/iot/orionGreeceUNISYSTEMS-UNIFI/Greece-UNISYSTEMS/PanaceaWebAppFeedback-" +
        username;
      const getResponse = await fetch(getFeedbackUrl, { headers })
        .then((response) => response.json())
        .then((data) => {
          setRating(
            data.realtime.results.bindings[0].stars.value
              ? parseInt(data.realtime.results.bindings[0].stars.value)
              : 3
          );
          setInput(
            data.realtime.results.bindings[0].description.value
              ? data.realtime.results.bindings[0].description.value
              : null
          );
        });
    };
    getParticipant();
  }, []);

  const sendFeedbackHandler = () => {
    // Set the headers with the bearer token
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You may need to adjust the content type
    };
    // Create the request body
    const requestBody = {
      dateObserved: {
        type: "string",
        value: new Date().toISOString(),
      },
      participantID: {
        value: username,
        type: "string",
      },
      stars: {
        value: rating.toString(),
        type: "string",
      },
      description: {
        value: input,
        type: "string",
      },
    };
    //dynamically constract the url
    const webFeedbackUrl = `https://iot-app.snap4city.org/orion-broker-panacea/v2/entities/PanaceaWebAppFeedback-${username}/attrs?type=Sensor&elementid=PanaceaWebAppFeedback-${username}`;
    // Send the PATCH request with headers
    fetch(webFeedbackUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        console.log("Response:", response.status);
        if (response.status !== 400) {
          alert("Feedback Sent", "Feedback has been successfully sent.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    console.log("Send Feedback");
  };

  const [rating, setRating] = useState(3);
  const [input, setInput] = useState("");
  // const [food, setFood] = useState(false);
  // const [service, setService] = useState(false);
  // const [quality, setQuality] = useState(false);
  // const [general, setGeneral] = useState(false);

  const onHover = (e) => {
    if (e.target.className === "star") {
      setRating(e.target.dataset.value);
    }
  };

  const onClick = (e) => {
    if (e.target.dataset.value === rating) {
      setRating(e.target.dataset.value);
      console.log("Rating is ==>" + rating);
    }
  };

  return (
    <div className={styles.outerContainer}>
      <ProgressTopBar />
      <div className={styles.questionView}>
        <p className={styles.titleText}>
          How likely are you to recommend the PANACEA app?
        </p>
        <div className={styles.customRateBar}>
          {[...Array(5).keys()].map((index) => {
            return (
              <img
                data-value={index + 1}
                className="star"
                onMouseOver={onHover}
                onClick={onClick}
                src={
                  index + 1 <= rating
                    ? require("../../images/star_filled.png")
                    : require("../../images/star_corner.png")
                }
                alt={index + 1 <= rating ? "filled star" : "empty star"}
              />
            );
          })}
        </div>
      </div>
      {/*<div className={styles.questionView}>
        <p className={styles.titleText}>
          What's your driving review? (optional)
        </p>
         <div className={styles.qualityRating}>
          <img
            className={styles.customImg}
            onClick={() => {
              setFood(!food);
            }}
            src={
              !food
                ? require("../../images/iconreview1-hd.png")
                : require("../../images/iconreview1-hd_on.png")
            }
            alt={!food ? "food not checked" : "food checked"}
          />
          <img
            className={styles.customImg}
            onClick={() => {
              setService(!service);
            }}
            src={
              !service
                ? require("../../images/iconreview2-hd.png")
                : require("../../images/iconreview2-hd_on.png")
            }
            alt={!service ? "service not checked" : "service checked"}
          />
          <img
            className={styles.customImg}
            onClick={() => {
              setQuality(!quality);
            }}
            src={
              !quality
                ? require("../../images/iconreview3-hd.png")
                : require("../../images/iconreview3-hd_on.png")
            }
            alt={!quality ? "quality not checked" : "quality checked"}
          />
          <img
            className={styles.customImg}
            onClick={() => {
              setGeneral(!general);
            }}
            src={
              !general
                ? require("../../images/iconreview4-hd.png")
                : require("../../images/iconreview4-hd_on.png")
            }
            alt={!general ? "general not checked" : "general checked"}
          /> 
        </div>
      </div>*/}
      <div className={styles.questionView}>
        <p className={styles.titleText}>Anything else? (optional)</p>
        <textarea
          className={styles.shareTxt}
          placeholder="Share your experience or offer suggestions to the staff..."
          multiple={true}
          maxLength={300}
          autoComplete="on"
          autoCapitalize="sentences"
          onChange={(e) => setInput(e.target.value)}
          value={input}
        />
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.buttons} onClick={sendFeedbackHandler}>
          {/* <CiPaperplane size={22} style={{ marginRight: 12 }} /> */}
          Send
        </button>
      </div>
    </div>
  );
};

export default FeedbackScreen;
