import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import RadioButtonQuestion from "../UI/RadioButtonQuestion";
import styles from "./CountermeasuresDebriefScreen.module.css";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";

const CountermeasuresDebriefScreen = ({ mode }) => {
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const id = global.selectedDriver;
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  let content = mode === "fatigue" ? "Fatigue" : "Drug";

  //state management for fatigue answers
  const [fatigueAnswers, setFatigueAnswers] = useState([]);

  const handleFatigueAnswerChange = (questionId, answer) => {
    setFatigueAnswers((prevFatigueAnswers) => [
      ...prevFatigueAnswers,
      { answer: answer, question: questionId },
    ]);
  };

  //state management for drug answers
  const [drugAnswers, setDrugAnswers] = useState([]);

  const handleDrugAnswerChange = (questionId, answer) => {
    setDrugAnswers((prevDrugAnswers) => [
      ...prevDrugAnswers,
      { answer: answer, question: questionId },
    ]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    switch (mode) {
      case "fatigue":
        const fatigueDebriefingReportUrl = `https://iot-app.snap4city.org/orion-broker-panacea/v2/entities/PanaceaFatigueDebriefingQuestionnaire-${id}/attrs?type=Sensor&elementid=PanaceaFatigueDebriefingQuestionnaire-${id}`;
        //call for updating
        const fatigueJsonObj = {
          dateObserved: {
            type: "string",
            value: new Date().toISOString(),
          },
          postEvaluationQuestionnaire: {
            type: "json",
            value: [
              ...fatigueAnswers,
              { answer: input, question: "free_text_answer_0" },
            ],
          },
        };
        const fatigueStringObj = JSON.stringify(fatigueJsonObj);

        fetch(fatigueDebriefingReportUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "PATCH",
          body: fatigueStringObj,
        })
          .then((response) => {
            console.log("UPDATE");
            if (response.status === 204) {
              alert("Successfully posted questionnaire.");
              navigate("/countermeasures")
            }
          })
          .catch((err) => {
            alert("Error. Questionnaire could not be posted.");
            console.log(err.message);
          });
        break;
      case "drug":
        const drugDebriefingReportUrl = `https://iot-app.snap4city.org/orion-broker-panacea/v2/entities/PanaceaDrugDebriefingQuestionnaire-${id}/attrs?type=Sensor&elementid=PanaceaDrugDebriefingQuestionnaire-${id}`;
        //call for updating
        const drugJsonObj = {
          dateObserved: {
            type: "string",
            value: new Date().toISOString(),
          },
          postEvaluationQuestionnaire: {
            type: "json",
            value: [
              ...drugAnswers,
              { answer: input, question: "free_text_answer_0" },
            ],
          },
        };
        const drugStringObj = JSON.stringify(drugJsonObj);

        fetch(drugDebriefingReportUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "PATCH",
          body: drugStringObj,
        })
          .then((response) => {
            console.log("UPDATE");
            if (response.status === 204) {
              alert("Successfully posted questionnaire.");
              navigate("/countermeasures")
            }
          })
          .catch((err) => {
            alert("Error. Questionnaire could not be posted.");
            console.log(err.message);
          });
        break;

      default:
        break;
    }
    console.log("Submitted");
  };
  return (
    <div className={styles.innerContainer}>
      <div className={styles.headerTxt}>
        {content} Countermeasures/Debriefing for {global.selectedDriver}
      </div>
      {mode === "fatigue" && (
        <div>
          <RadioButtonQuestion
            questionId="checkbox_0"
            onAnswerChange={handleFatigueAnswerChange}
          >
            The driver exhibits appropriate fatigue management behaviour on the
            shift
          </RadioButtonQuestion>
          <RadioButtonQuestion
            questionId="checkbox_1"
            onAnswerChange={handleFatigueAnswerChange}
          >
            The driver exhibits appropriate fatigue management behaviour off the
            shift
          </RadioButtonQuestion>
          <RadioButtonQuestion
            questionId="checkbox_2"
            onAnswerChange={handleFatigueAnswerChange}
          >
            The driver's shift contributions allow them to stay alert on the job
          </RadioButtonQuestion>
        </div>
      )}
      {mode === "drug" && (
        <div>
          <RadioButtonQuestion
            questionId="checkbox_0"
            onAnswerChange={handleDrugAnswerChange}
          >
            The driver has sufficient knowledge of the drug and its effect
          </RadioButtonQuestion>
          <RadioButtonQuestion
            questionId="checkbox_1"
            onAnswerChange={handleDrugAnswerChange}
          >
            The driver has sufficient knowledge of safety and legal consequences
          </RadioButtonQuestion>
          <RadioButtonQuestion
            questionId="checkbox_2"
            onAnswerChange={handleDrugAnswerChange}
          >
            The driver is responsible in their drug use concerning driving
          </RadioButtonQuestion>
        </div>
      )}

      <div className={styles.questionView}>
        <p className={styles.titleText}>
          The following important issues were raised:
        </p>
        <textarea
          id="free_text_answer_0"
          className={styles.shareTxt}
          placeholder="Write here ..."
          multiple={true}
          maxLength={300}
          autoComplete="on"
          autoCapitalize="sentences"
          onChange={(e) => {setInput(e.target.value)}}
          value={input}
        />
      </div>
      <Button onClick={handleSubmit}>Submit</Button>
    </div>
  );
};

export default CountermeasuresDebriefScreen;
