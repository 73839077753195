import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPass } from "../../util/auth";
import Button from "../UI/Button";
import styles from "./ForgotPassword.module.css";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [enteredEmail, setEnteredEmail] = useState("");

  const sendEmailHandler = () => {
    try {
      resetPass(enteredEmail);
      navigate("/auth", { replace: true });
      alert("Email sent successfully! Please check your spam.");
    } catch (error) {
      console.log(error);
    }
  };

  const cancelHandler = () => {
    navigate("/auth", { replace: true });
  };

  return (
    <div className={styles.authContent}>
      <p className={styles.text}>Retrieve your password</p>
      <div className={styles.control}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={enteredEmail}
          required
          onChange={(e) => setEnteredEmail(e.target.value)}
        />
      </div>
      <div className={styles.innerContainer}>
        <Button style={styles.buttons} onClick={cancelHandler}>
          Cancel
        </Button>
        <Button style={styles.buttons} onClick={sendEmailHandler}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ForgotPassword;
