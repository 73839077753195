import FatigueQuestionnaireScreen from "../components/screens/FatigueQuestionnaireScreen";

const FatigueQuestionnairePage = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <FatigueQuestionnaireScreen />
    </div>
  );
};

export default FatigueQuestionnairePage;
