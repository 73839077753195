import { useState } from "react";
import { Alerts } from "../../constants/alerts";
import { Notify } from "../../constants/notify";
import styles from "./AlertNotification.module.css";
import { AiOutlineInfoCircle } from "react-icons/ai";

const AlertNotificationScreen = () => {
  const List = [];
  console.log(Alerts);
  console.log(Notify);

  const [alertVis, setAlertVis] = useState(true);

  const pressHandler = () => {
    setAlertVis(!alertVis);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container2}>
        <button
          onClick={pressHandler}
          style={{
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            backgroundColor: !alertVis ? "#e0f4f3" : "#337c75",
            color: !alertVis ? "#337c75" : "white",
            border: "none",
            width: "25%",
          }}
        >
          <p className={styles.tabTitle}>
            Alerts
            <AiOutlineInfoCircle
              size={24}
              color={!alertVis ? "#337c75" : "white"}
              onClick={() => {
                alert("Alerts are the information that derive from the DSS and require action.");
              }}
            />
          </p>
        </button>
        <button
          onClick={pressHandler}
          style={{
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            backgroundColor: alertVis ? "#e0f4f3" : "#337c75",
            color: alertVis ? "#337c75" : "white",
            border: "none",
            width: "25%",
          }}
        >
          <p className={styles.tabTitle}>
            Notifications
            <AiOutlineInfoCircle
              size={24}
              color={alertVis ? "#337c75" : "white"}
              onClick={() => {
                alert("Notifications are the information that derive from Countermeasures and make suggestions.");
              }}
            />
          </p>
        </button>
      </div>
      {alertVis ? (
        <div className={styles.contentContainer}>
          {Alerts.length === 0 ? (
            <p className={styles.paragraph}>No Alerts received yet.</p>
          ) : (
            Alerts.map((item) => <p className={styles.item_list}>- {item}</p>)
          )}
        </div>
      ) : (
        <div className={styles.contentContainer}>
          {Notify.length === 0 ? (
            <p className={styles.paragraph}>No Notifications received yet.</p>
          ) : (
            Notify.map((item) => <p className={styles.item_list}>- {item}</p>)
          )}
        </div>
      )}
    </div>
  );
};

export default AlertNotificationScreen;
