import ProgressTopBar from "../UI/ProgressTopBar";
import styles from "./AnalyticsScreen.module.css";

const AnalyticsScreen = ({ admin }) => {
  return (
    <>
      {!admin && <ProgressTopBar />}
      <div className={styles.container}>
        <iframe
          title="Analytics Dashboard"
          className={styles.iframe}
          src={
            "https://snapshots.raintank.io/dashboard/snapshot/ejjrsFVgq761YyAeDBsZKDrJ0WOf7DMs" +
            "?&kiosk"
          }
        />
      </div>
    </>
  );
};

export default AnalyticsScreen;
