import React, { useState } from "react";

const EmojiButton = ({ children, emoji, onPress }) => {
  const [pressed, setPressed] = useState(false);

  const styles = {
    container: {
        width:"20vh",
        height:"20vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: pressed
        ? "#337c75"
        : "transparent",
      padding: 8,
      borderRadius: 4,
    },
    text: {
      fontSize: 16,
      fontWeight: "bold",
      color: pressed ? "white" : "#337c75",
      marginVertical: 16,
    },
    pressed: {
      opacity: 0.75,
    },
  };

  const handlePress = () => {
    setPressed(!pressed);
    onPress(children);
  };

  return (
    <div>
      <button
        onClick={handlePress}
        style={pressed ? { ...styles.container, ...styles.pressed } : styles.container}
      >
        <p style={styles.text}>{children}</p>
        <p style={{ fontSize: 36 }}>{emoji}</p>
      </button>
    </div>
  );
};

export default EmojiButton;
