import { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthContext from "./store/auth-context";
import Layout from "./components/Layout/Layout";
import SideBar from "./components/UI/SideBar";
import InfoPage from "./components/Info/InfoScreen";
import AuthPage from "./pages/AuthPage";
import MyProfilePage from "./pages/MyProfilePage";
import AlertsNotificationsPage from "./pages/AlertsNotificationsPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import FeedbackPage from "./pages/FeedbackPage";
import ContactPage from "./pages/ContactPage";
import DriversPage from "./pages/DriversPage";
import SettingsPage from "./pages/SettingsPage";
import ForgotPassword from "./components/screens/ForgotPassword";
import DriversShiftPage from "./pages/DriversShiftPage";
import ManagingUsersPage from "./pages/ManagingUsersPage";
import FatigueReportPage from "./pages/FatigueReportPage";
import FatigueQuestionnairePage from "./pages/FatigueQuestionnairePage";
import StressManagementPage from "./pages/StressManagementPage";
import StressManagementRelaxPage from "./pages/StressManagementRelaxPage";
import StressManagementResultsPage from "./pages/StressManagementResultsPage";
import StressManagementTimerPage from "./pages/StressManagementTimerPage";
import CountermeasuresPage from "./pages/CountermeasuresPage";
import CountermeasuresDebriefPage from "./pages/CountermeasuresDebriefPage";
import ReactGA from "react-ga";
import ActivityFeedPage from "./pages/ActivityFeedPage";
import PrivacyDisclaimer from "./components/Info/PrivacyDisclaimer";

ReactGA.initialize("G-H3B3R8CHFK");

function App() {
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const privateRoute = (path, component) => {
    return authCtx.isLoggedIn ? (
      <>
        <SideBar loggedIn={authCtx.isLoggedIn} />
        {component}
      </>
    ) : (
      <Navigate to="/auth" />
    );
  };

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <SideBar loggedIn={authCtx.isLoggedIn} />
              <InfoPage />
            </>
          }
        />
        <Route path="/privacy_disclaimer" element={<PrivacyDisclaimer />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/forgotpass" element={<ForgotPassword />} />
        <Route
          path="/profile"
          element={privateRoute("/profile", <MyProfilePage />)}
        />
        <Route
          path="/drivers"
          element={privateRoute("/drivers", <DriversPage />)}
        />
        <Route
          path="/drivers_shift"
          element={privateRoute("/drivers_shift", <DriversShiftPage />)}
        />
        <Route
          path="/manage_users"
          element={privateRoute("/manage_users", <ManagingUsersPage />)}
        />
        <Route
          path="/alerts&notifications"
          element={privateRoute(
            "/alerts&notifications",
            <AlertsNotificationsPage />
          )}
        />
        <Route
          path="/analytics"
          element={privateRoute("/analytics", <AnalyticsPage />)}
        />
        <Route
          path="/activity_feed"
          element={privateRoute("/activity_feed", <ActivityFeedPage />)}
        />
        <Route
          path="/fatigue_report"
          element={privateRoute("/fatigue_report", <FatigueReportPage />)}
        />
        <Route
          path="/fatigue_questionnaire"
          element={privateRoute(
            "/fatigue_questionnaire",
            <FatigueQuestionnairePage />
          )}
        />
        <Route
          path="/stress_management"
          element={privateRoute("/stress_management", <StressManagementPage />)}
        />
        <Route
          path="/stress_relax"
          element={privateRoute("/stress_relax", <StressManagementRelaxPage />)}
        />
        <Route
          path="/stress_results"
          element={privateRoute(
            "/stress_results",
            <StressManagementResultsPage />
          )}
        />
        <Route
          path="/stress_timer"
          element={privateRoute("/stress_timer", <StressManagementTimerPage />)}
        />
        <Route
          path="/countermeasures"
          element={privateRoute("/countermeasures", <CountermeasuresPage />)}
        />
        <Route
          path="/countermeasures_fatigue_debrief"
          element={privateRoute(
            "/countermeasures_fatigue_debrief",
            <CountermeasuresDebriefPage mode="fatigue" />
          )}
        />
        <Route
          path="/countermeasures_drug_debrief"
          element={privateRoute(
            "/countermeasures_drug_debrief",
            <CountermeasuresDebriefPage mode="drug" />
          )}
        />
        <Route
          path="/feedback"
          element={privateRoute("/feedback", <FeedbackPage />)}
        />
        <Route
          path="/contact"
          element={privateRoute("/contact", <ContactPage />)}
        />
        <Route
          path="/settings"
          element={privateRoute("/settings", <SettingsPage />)}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}

export default App;
