import Notifications from "../components/UI/Notifications";

const DriversPage = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <p
        style={{
          color: "#337c75",
          fontSize: "36px",
          textAlign: "center",
          alignContent: "center",
        }}
      >
        Welcome <strong>{global.loggedInUserName}</strong> to Panacea.
      </p>
      <Notifications role={global.loggedInUserRole} />
    </div>
  );
};

export default DriversPage;
